import { ApolloClient, InMemoryCache } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import { appConfig } from '../config';
const { networkInterface: uri } = appConfig.showcase;

const cache = new InMemoryCache();

/* eslint-disable no-process-env */
const isDevEnv = process.env['NODE_ENV'] !== 'production';
/* eslint-enable no-process-env */

const link = new RestLink({
  uri: uri || "https://api.bcrumbs.net",
});

const showcaseRendererClient = new ApolloClient({
  link,
  cache,
  connectToDevTools: isDevEnv,
  queryDeduplication: true,
});

export { showcaseRendererClient };
