import { LangService } from '../services/i18n';

export class StringsUtils {
  static localCompareStrings(a: string, b: string): number {
    const language = LangService.getLang();
    return a
      .toLocaleLowerCase(language ?? 'tr')
      .localeCompare(b.toLocaleLowerCase(language ?? 'tr'), language ?? 'tr');
  }

  static localeIncludeString(a: string, b: string): boolean {
    const language = LangService.getLang();
    return a
      .toLocaleLowerCase(language ?? 'tr')
      .includes(b.toLocaleLowerCase(language ?? 'tr'));
  }

  static isStringHasCharacters(text?: string | null) {
    return text && text.length > 0;
  }
}
