import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import { dconfigClient } from '../../../clients/dconfigClient';
import {
  ContentInstanceField,
  ContentInstancesQueryResult,
} from '../../../models/contentInstance';
import { Content } from '../../../models/content';

export const contentInstancesQuery = gql`
  query contentInstances($contentId: Int!) {
    contentInstances(contentId: $contentId)
      @rest(
        type: "ContentInstance"
        path: "/DConfig/WebsiteContentAPI/GetContentInstances/{args.contentId}"
        method: "GET"
      ) {
      Id
      Name
      MetaDescription
      MetaKeywords
      Title
      ViewTemplateId
      Online
      ContentId
      Language
      Version
      CreateDate
      FieldsValues
      RedirectUrl
      DownloadPath
      DownloadName
      CreatorId
      StageId
      Stage
      Comments
      ContextCompanyId
      Data
      DataDic
    }
  }
`;

export const useContentInstancesQuery = (contentId: number) =>
  useQuery<ContentInstancesQueryResult>(contentInstancesQuery, {
    fetchPolicy: 'no-cache',
    client: dconfigClient,
    variables: { contentId },
  });

export const updateContentInstanceFieldValuesMutation = gql`
  mutation ($body: JSON!) {
    updateContentInstanceFieldValues(body: $body)
      @rest(
        type: "UpdateContentInstanceType"
        path: "/DConfig/WebsiteContentAPI/UpdateContentInstanceFieldValues"
        method: "PUT"
        bodyKey: "body"
      ) {
      result
      message
    }
  }
`;

export const updateContentInstanceFieldValuesMutationOptions: any = {
  options: {
    client: dconfigClient,
  },
  props: ({
    ownProps,
    mutate,
  }: {
    ownProps: any;
    mutate: (payload: any) => any;
  }): any => ({
    async updateContentInstanceFieldValues(
      Id: number,
      FieldsValues: ContentInstanceField[]
    ) {
      try {
        const payload = { variables: { body: { Id, FieldsValues } } };
        const result = await mutate(payload);
        return Promise.resolve(result.data.updateContentInstanceFieldValues);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
};

export const useUpdateContentInstanceFieldValuesMutation = () =>
  useMutation(updateContentInstanceFieldValuesMutation, {
    client: dconfigClient,
  });

export const createContentMutation = gql`
  mutation ($body: JSON!) {
    createContent(body: $body)
      @rest(
        type: "CreateContentType"
        path: "/DConfig/WebsiteContentAPI/CreateContent"
        method: "POST"
        bodyKey: "body"
      ) {
      result
      message
      obj {
        Id
      }
    }
  }
`;
export const createContentMutationOptions: any = {
  options: {
    client: dconfigClient,
  },
  props: ({
    ownProps,
    mutate,
  }: {
    ownProps: any;
    mutate: (payload: any) => any;
  }): any => ({
    async createContent({
      Name,
      ParentId,
      ModelId,
    }: {
      Name: string;
      ParentId: number;
      ModelId: number;
    }) {
      try {
        const payload = {
          variables: {
            body: {
              Id: 0,
              Name,
              ViewTypeId: ModelId,
              Online: true,
              PlentyChildren: false,
              ContentType: 0,
              ContentInstances: [],
              StageId: null,
              ParentId,
            },
          },
        };
        const result = await mutate(payload);
        return Promise.resolve(result.data.updateContentInstanceFieldValues);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
};

export const useCreateContentMutation = () =>
  useMutation(createContentMutation, {
    client: dconfigClient,
  });

export const createContentInstanceMutation = gql`
  mutation ($body: JSON!) {
    createContent(body: $body)
      @rest(
        type: "CreateContentInstanceType"
        path: "/DConfig/WebsiteContentAPI/CreateContentInstance"
        method: "POST"
        bodyKey: "body"
      ) {
      result
      message
      obj {
        Id
      }
    }
  }
`;
export const createContentInstanceMutationOptions: any = {
  options: {
    client: dconfigClient,
  },
  props: ({
    ownProps,
    mutate,
  }: {
    ownProps: any;
    mutate: (payload: any) => any;
  }): any => ({
    async createContentInstance({
      Name,
      ContentId,
    }: {
      Name: string;
      ContentId: number;
    }) {
      try {
        const payload = {
          variables: {
            body: {
              Id: 0,
              ContentId,
              Name,
              Title: '',
              MetaDescription: '',
              MetaKeywords: '',
              Version: 0,
              DownloadPath: '',
              DownloadName: '',
              Online: true,
              ViewTemplateId: '',
              FieldsValues: [],
              StageId: null,
              DueDate: null,
            },
          },
        };
        const result = await mutate(payload);
        return Promise.resolve(result.data.updateContentInstanceFieldValues);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
};

export const useCreateContentInstanceMutation = () =>
  useMutation(createContentInstanceMutation, {
    client: dconfigClient,
  });

export const removeContentMutation = gql`
  mutation ($Id: Int!, $Name: String!) {
    deleteContent(Id: $Id, Name: $Name)
      @rest(
        type: "DeleteContentType"
        path: "/DConfig/WebsiteContentAPI/DeleteContent?{args}"
        method: "DELETE"
      ) {
      result
      message
    }
  }
`;

export const useDeleteContentMutation = () =>
  useMutation(removeContentMutation, {
    client: dconfigClient,
  });

export const updateContentsOrderingMutation = gql`
  mutation ($body: JSON!) {
    updateContentsOrdering(body: $body)
      @rest(
        type: "UpdateContentOrderingType"
        path: "/DConfig/WebsiteContentAPI/updateContentsOrdering"
        method: "PUT"
        bodyKey: "body"
      ) {
      result
      message
    }
  }
`;

export const updateContentsOrderingMutationOptions: any = {
  options: {
    client: dconfigClient,
  },
  props: ({
    ownProps,
    mutate,
  }: {
    ownProps: any;
    mutate: (payload: any) => any;
  }): any => ({
    async updateContentsOrderingMutation(
      Contents: Content[],
    ) {
      try {
        const payload = { variables: { body: { Contents } } };
        const result = await mutate(payload);
        return Promise.resolve(result.data.updateContentsOrderingMutation);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
};

export const useUpdateContentsOrderingMutation = () =>
  useMutation(updateContentsOrderingMutation, {
    client: dconfigClient,
  });
