import {
  ApolloClient,
  InMemoryCache,
  from,
  createHttpLink,
  ServerError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { appConfig } from '../config';
const { networkInterface: uri } = appConfig.core;

async function getUserToken() {
  return new Promise((resolve) => {
    if (typeof window !== 'undefined') {
      const storedToken = window.localStorage.getItem('token');
      resolve(storedToken);
    }

    resolve(undefined);
  });
}

const authLink = setContext(async (operation, { headers }) => {
  const currentUsertoken = await getUserToken();
  if (headers === undefined) {
    headers = {};
  }
  const resultHeaders = currentUsertoken
    ? {
        headers: {
          ...headers,
          Accept: 'application/json',
          Authorization: `Bearer ${currentUsertoken}`,
        },
      }
    : {
        headers: {
          ...headers,
          Accept: 'application/json',
        },
      };
  return resultHeaders;
});

const cache = new InMemoryCache();

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError && (networkError as ServerError).statusCode === 401) {
    // redirect to login page
    if (typeof window !== 'undefined') {
      return window.location.replace('/account/login');
    }
  } else if (
    networkError &&
    (networkError as ServerError).statusCode > 500 &&
    (networkError as ServerError).statusCode !== 503
  ) {
    if (typeof window !== 'undefined') {
      return window.location.replace('/error?message=' + networkError.message);
    }
  }

  if (
    graphQLErrors &&
    graphQLErrors.find((error) => error?.extensions?.['code'] === 'FORBIDDEN')
  ) {
    const error = graphQLErrors.find(
      (error) => error?.extensions?.['code'] === 'FORBIDDEN'
    );
    return window.location.replace('/403?message=' + error?.message);
  }
});

/* eslint-disable no-process-env */
const isDevEnv = process.env['NODE_ENV'] !== 'production';
/* eslint-enable no-process-env */

const link_dquery = createHttpLink({
  uri: `${uri}/gq`,
  fetchOptions: {
    mode: 'cors',
  },
});

export const coreClient = new ApolloClient({
  link: from([authLink, errorLink, link_dquery]),
  cache,
  connectToDevTools: isDevEnv,
  queryDeduplication: true,
});
