import { PropsWithChildren, ReactElement, useEffect } from 'react';
import styled from '@emotion/styled';
import { BCIcon } from '../icon/bcicon';
import IconType from '../constants/IconType';
import Color from '../constants/Color';
import Key from '../constants/Key';
import { BCOverlay, OverlayContentPosition } from '../overlay';

export type BCPopupProps = {
  onClose: () => void;
  onEnter?: () => void;
  header?: ReactElement[] | ReactElement;
  buttons?: ReactElement[];
  footerContent?: ReactElement[] | ReactElement;
  width?: number;
  minHeight?: number;
  minWidth?: number;
  showCloseIcon?: boolean;
  closeOnOverlayClick?: boolean;
  closeOnEsc?: boolean;
  stopPropagationOnClick?: boolean;
  icon?: IconType;
  dataQA?: string;
  className?: string;
  padding?: string;
  presumedHeight?: number;
  overlayContentPosition?: OverlayContentPosition;
};

export const BCPopupContainer = styled.div<{
  width?: number;
  minHeight?: number;
  minWidth?: number;
  transparent?: boolean;
  overlayContentPosition?: OverlayContentPosition;
}>`
  margin: auto;
  position: relative;
  z-index: 6;
  overflow: auto;
  width: ${({ width }) => `${width}px`};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : 'auto')};
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : 'auto')};
  height: ${({ overlayContentPosition }) =>
    overlayContentPosition &&
      overlayContentPosition === OverlayContentPosition.RightSide ||
      overlayContentPosition === OverlayContentPosition.LeftSide
      ? `100%`
      : 'auto'};
  background-color: ${({ transparent }) =>
    transparent ? 'transparent' : Color.CONTROLS_COLOR};
  border-top-left-radius: ${({ overlayContentPosition }) =>
    overlayContentPosition &&
      overlayContentPosition === OverlayContentPosition.LeftSide
      ? `0`
      : '12px'};
  border-top-right-radius: ${({ overlayContentPosition }) =>
    overlayContentPosition &&
      overlayContentPosition === OverlayContentPosition.RightSide
      ? `0`
      : '12px'};
  border-bottom-left-radius: ${({ overlayContentPosition }) =>
    overlayContentPosition &&
      overlayContentPosition === OverlayContentPosition.LeftSide
      ? `0`
      : '12px'};
  border-bottom-right-radius: ${({ overlayContentPosition }) =>
    overlayContentPosition &&
      overlayContentPosition === OverlayContentPosition.RightSide
      ? `0`
      : '12px'};
`;

const BodyWrapper = styled.div<{ padding?: string; headerExists?: boolean }>`
  padding: ${({ padding }) => padding};
  line-height: 1.5;
  height: ${({ headerExists }) =>
    headerExists ? 'calc(100% - 77px)' : '100%'};
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Color.PRIMARY10};
  padding: 24px;
  line-height: 1.5;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 17px;
`;

const FooterLeftContentsWrapper = styled.div`
  padding: 14px 24px;
  justify-content: flex-start;
  align-self: flex-start;
  flex: 0.5;
`;

const ButtonsWrapper = styled.div`
  padding: 8px 24px 24px;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  flex: 0.5;
  & > *:nth-child(n + 2) {
    margin-left: 8px;
    margin-right: 8px;
  }
`;

const FlexBoxJustifyEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export function BCPopup({
  overlayContentPosition = OverlayContentPosition.CenterScreen,
  header,
  children,
  buttons,
  width = 500,
  minHeight = 200,
  minWidth,
  showCloseIcon = true,
  closeOnOverlayClick = true,
  closeOnEsc = true,
  onClose,
  onEnter,
  stopPropagationOnClick = true,
  icon,
  dataQA,
  className,
  padding = '24px',
  presumedHeight,
  footerContent,
}: PropsWithChildren<BCPopupProps>) {
  useEffect(() => {
    function onKeyPressHandler(event: KeyboardEvent) {
      if (event.key === Key.Enter) {
        onEnter?.();
      }
    }

    window.addEventListener('keypress', onKeyPressHandler);

    return () => {
      window.removeEventListener('keypress', onKeyPressHandler);
    };
  });

  const headerContent = header ? (
    <HeaderWrapper>
      {icon ? (
        <IconWrapper>
          <BCIcon icon={icon} />
        </IconWrapper>
      ) : null}
      {header}
    </HeaderWrapper>
  ) : null;

  const footer =
    (buttons && buttons.length) || footerContent ? (
      <FlexBoxJustifyEnd>
        {footerContent ? (
          <FooterLeftContentsWrapper>{footerContent}</FooterLeftContentsWrapper>
        ) : null}
        {buttons ? <ButtonsWrapper>{buttons}</ButtonsWrapper> : null}
      </FlexBoxJustifyEnd>
    ) : null;

  return (
    <BCOverlay
      showCloseIcon={showCloseIcon}
      closeOnOverlayClick={closeOnOverlayClick}
      closeOnEsc={closeOnEsc}
      stopPropagationOnClick={stopPropagationOnClick}
      onCloseHandler={onClose}
      presumedHeight={presumedHeight}
      overlayContentPosition={overlayContentPosition}
    >
      <BCPopupContainer
        width={width}
        minHeight={minHeight}
        minWidth={minWidth}
        data-qa={dataQA}
        className={className}
        overlayContentPosition={overlayContentPosition}
      >
        {headerContent}
        <BodyWrapper
          className="popin-body"
          padding={padding}
          headerExists={!!header}
        >
          {children}
        </BodyWrapper>
        {footer}
      </BCPopupContainer>
    </BCOverlay>
  );
}
