import React, { Suspense } from 'react';
import { ApolloProvider } from '@apollo/client';
import { dconfigClient } from '@bcrumbs.net/bc-api';
import { Loading } from './app/generic/components/Loading';

const App = React.lazy(() => import('./app'));

function AppWrapper() {
  return (
    <Suspense
      fallback={
        <Loading />
      }
    >
      <ApolloProvider client={dconfigClient}>
        <App />
      </ApolloProvider>
    </Suspense>
  );
}

export default AppWrapper;
