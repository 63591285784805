import styled from 'styled-components';
import { BCTooltip, TooltipPlacement } from '../tooltip';
import LeftNav from './left-nav';
import { NavType } from './types';
import { Color } from '../constants';

export interface BCNavigationProps {
  brand?: string;
  handleLeftNavItemClick: (event: any, action?: string) => void;
  navModel: NavType;
  location: any;
  rtl: boolean;
}

const NavbarStyle = styled.nav<{ rtl: boolean }>`
  height: 100%;
  height: -webkit-fill-available;
  width: 42px;
  position: fixed;
  z-index: 1;
  ${({ rtl }) => (rtl ? 'right: 0;' : 'left: 0;')};
  background-color: var(--bc-primary-color);
  padding-top: 20px;

  .nav {
    padding: 0;
    position: absolute;
    top: calc(50vh - 122px);
    width: 100%;
  }

  .nav.bottom {
    bottom: 0px;
    top: auto;
  }

  .nav a {
    padding-top: 7px;
    display: inline-block;
    margin: 0;
  }

  .nav li {
    text-align: center;
    margin: 6px 0;
  }

  .nav li.active {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    ${({ rtl }) =>
      rtl
        ? `border-right: 4px solid ${Color.PRIMARY_LIGHTER}`
        : `border-left: 4px solid ${Color.PRIMARY_LIGHTER}`};
    i {
      color: ${Color.PRIMARY_LIGHTER};
    }
    svg {
      fill: ${Color.PRIMARY_LIGHTER};
      color: ${Color.PRIMARY_LIGHTER};
    }
  }

  .navbar-brand {
    ${({ rtl }) => (rtl ? 'margin-right: 9px;' : 'margin-left: 9px;')};
  }

  .nav.bottom {
    bottom: 0px;
    top: auto;
  }
`;

export const BCNavigation = ({
  brand,
  navModel,
  handleLeftNavItemClick,
  location,
  rtl,
}: BCNavigationProps) => (
  <NavbarStyle rtl={rtl}>
    <div className="navbar-header">
      <BCTooltip title="Home" placement={TooltipPlacement.RIGHT}>
        <a href={'/'} className="navbar-brand">
          {brand ? <img src={brand} width={26} /> : <i className="bc-logo"></i>}
        </a>
      </BCTooltip>
    </div>
    <LeftNav
      leftLinks={navModel.leftLinks}
      onLeftNavButtonClick={handleLeftNavItemClick}
      location={location}
    />
    <LeftNav
      leftLinks={navModel.leftBottomLinks}
      onLeftNavButtonClick={handleLeftNavItemClick}
      className="bottom"
      location={location}
    />
  </NavbarStyle>
);

export default BCNavigation;
