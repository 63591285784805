import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { dconfigClient } from '../../../clients/dconfigClient';
import { ModelQueryResult } from '../../../models/model';

export const viewTypesQuery = gql`
  query ($templateContextId: Int!) {
    viewTypes(templateContextId: $templateContextId)
      @rest(
        type: "Model"
        path: "/DConfig/WebsiteContentAPI/getViewTypes?templateContextId={args.templateContextId}"
        method: "GET"
      ) {
      Id
      Name
      ViewFields {
        Id
        Name
        Type
        EnumId
      }
    }
  }
`;
export const viewTypesQueryOptions = {
  options: () => {
    return { client: dconfigClient };
  },
  name: 'viewTypes',
  props: ({
    viewTypes: { loading, viewTypes, refetch },
  }: {
    viewTypes: { loading: boolean; viewTypes: any; refetch: any };
  }) => {
    return {
      models: viewTypes,
      refetchModels: refetch,
      loadingModels: loading,
    };
  },
};

export const useModelsQuery = (templateContextId?: number) =>
  useQuery<ModelQueryResult>(viewTypesQuery, {
    fetchPolicy: 'cache-first',
    client: dconfigClient,
    variables: { templateContextId },
  });

export const viewTypeChildrenQuery = gql`
  query ($parentId: Int!, $templateId: Int!) {
    viewTypes(parentId: $parentId, templateId: $templateId)
      @rest(
        type: "Model"
        path: "/DConfig/WebsiteContentAPI/getViewTypeChildren?Id={args.parentId}&templateContextId={args.templateId}"
        method: "GET"
      ) {
      Id
      Name
      ViewFields {
        Id
        Name
        Type
        EnumId
      }
    }
  }
`;

export const viewTypeChildrenQueryOptions = {
  options: () => {
    return { client: dconfigClient };
  },
  name: 'viewTypes',
  props: ({
    viewTypes: { loading, viewTypes, refetch },
  }: {
    viewTypes: { loading: boolean; viewTypes: any; refetch: any };
  }) => {
    return {
      models: viewTypes,
      refetchModels: refetch,
      loadingModels: loading,
    };
  },
};

export const useModelChildrenQuery = (
  parentId?: number,
  templateId?: number
) =>
  useQuery<ModelQueryResult>(viewTypeChildrenQuery, {
    fetchPolicy: 'cache-first',
    client: dconfigClient,
    variables: { parentId, templateId },
  });
