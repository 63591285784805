import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import classNames from 'classnames';
import {
  BCValidations,
  BCDefaultValidationMessages,
  BCValidationTypes,
} from './validationConstant';
import { BCDropList, BCDropListOptionsType } from '../../dropList/bcdrop-list';
import { BCTextbox } from '../../textbox/bctextbox';
import { BCTooltip } from '../../tooltip';
import { FIELDS_TYPES } from './fieldsTypes';
import { Color } from '../../constants';
import { BCError } from '../../text/bctext';

export const FormGroup = styled.div`
  position: relative;
  margin: 4px 0 8px;
  button {
    margin: 0;
    outline: none;
  }
  label {
    margin-bottom: 4px;
    margin: 20px 0px 5px;
    width: 100%;
    font-weight: 500;
  }
  input.mb-has-error {
    border-color: ${Color.DANGER_COLOR};
  }
`;

interface FormData {
  value: string;
  isValid: boolean;
  message: string;
  validations?: FormValidation[];
}

interface FormField {
  value: string;
  validations?: FormValidation[];
}

interface FormValidation {
  validationType: BCValidationTypes;
  additionalParam?: string;
}

type FieldType =
  | 'password'
  | 'text'
  | 'number'
  | 'boolean'
  | 'droplist'
  | 'file'
  | 'email';

interface useBCFormReturnFunctions {
  setFieldValue: (
    name: string,
    value: string,
    validations?: FormValidation[]
  ) => void;
  setFieldError: (
    name: string,
    error: string
  ) => void;
  validateField: (
    key: string,
    validationType: BCValidationTypes,
    additionalParam?: string
  ) => boolean;
  validateForm: () => boolean;
  onChange: (targetVal: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  resetValidationStates: () => void;
  renderField: (
    name: string,
    label: string,
    type: FieldType,
    readonly?: boolean,
    placeholder?: string,
    tooltip?: string
  ) => JSX.Element;
  _renderFieldInput: (
    name: string,
    type: FieldType,
    readonly: boolean,
    placeholder: string
  ) => JSX.Element;
  onDroplistSelectionChange: (name: string, selectedKey?: string) => void;
  renderDroplistField: (
    name: string,
    label: string,
    options: BCDropListOptionsType[],
    placeholder?: string,
    tooltip?: string
  ) => JSX.Element;
}

type useMBFormReturnType = [
  { [key: string]: FormData },
  useBCFormReturnFunctions
];

function getInitialData(formInitialValue?: { [key: string]: FormField }){
  if (formInitialValue) {
    const formRes : { [key: string]: FormData } = {};
    Object.keys(formInitialValue).forEach(x => {
        formRes[x] = {
          value: formInitialValue[x].value,
          isValid: true,
          message: '',
          validations: formInitialValue[x].validations
        };
    });

    return { ...formRes };
  }

  return {};
}

export const useBCForm = (
  formInitialValue?: { [key: string]: FormField },
  t?: any
): useMBFormReturnType => {
  const [formData, setFormData] = useState<{ [key: string]: FormData }>(getInitialData(formInitialValue));

  useEffect(() => {
    setFormData(getInitialData(formInitialValue));
  }, [formInitialValue]);

  const setFieldValue = (name: string, value: string) => {
    formData[name] = { ...formData[name], value, isValid: true, message: '' };
    setFormData({ ...formData });
  };

  const setFieldError = (name: string, error: string) => {
    formData[name] = { value: formData[name].value, isValid: false, message: error };
    setFormData({ ...formData });
  }

  const _validateField = (
    key: string,
    validationType: BCValidationTypes,
    additionalParam?: string
  ) => {
    const targetVariable = { ...formData[key] };
    let isGood = true;
    switch (validationType) {
      case BCValidationTypes.NOT_EMPTY:
        if (!(targetVariable.value.length > 0)) {
          targetVariable.isValid = false;
          targetVariable.message = BCDefaultValidationMessages.emptyMessage;
          isGood = false;
        }
        break;
      case BCValidationTypes.NAME_2_LETTERS:
        if (targetVariable.value.length < 2) {
          targetVariable.isValid = false;
          targetVariable.message = BCDefaultValidationMessages.nameMessage;
          isGood = false;
        }
        break;
      case BCValidationTypes.EMAIL:
        if (!BCValidations.emailValidation.test(targetVariable.value)) {
          targetVariable.isValid = false;
          targetVariable.message = BCDefaultValidationMessages.emailMessage;
          isGood = false;
        }
        break;
      case BCValidationTypes.PASSWORD:
        if (!BCValidations.passwordValidation.test(targetVariable.value)) {
          targetVariable.isValid = false;
          targetVariable.message = BCDefaultValidationMessages.passwordMessage;
          isGood = false;
        }
        break;
      case BCValidationTypes.CONFIRM_PASSWORD:
        if (
          additionalParam &&
          targetVariable.value !== formData[additionalParam].value
        ) {
          targetVariable.isValid = false;
          targetVariable.message =
            BCDefaultValidationMessages.confirmPasswordMessage;
          isGood = false;
        }
        break;
      case BCValidationTypes.DOMAIN:
        if (!BCValidations.domain.test(targetVariable.value)) {
          targetVariable.isValid = false;
          targetVariable.message = BCDefaultValidationMessages.domainMessage;
          isGood = false;
        }
        break;
      default:
        break;
    }
    return targetVariable;
  };

  const validateField = (
    key: string,
    validationType: BCValidationTypes,
    additionalParam?: string
  ) => {
    const targetVariable = _validateField(key, validationType, additionalParam);
    setFormData({ ...formData, [key]: targetVariable });
    return targetVariable.isValid;
  };

  /**
   * In order to validate form all fields should contain validations array in their params
   */
  const validateForm = () => {
    const updatedFormData = Object.assign({},formData);
    const result = Object.keys(updatedFormData)
      .map((key) => {
        if (formData && formData[key] && formData[key].validations) {
          const validationsResults = formData[key]?.validations?.map((val) =>
            _validateField(key, val.validationType, val.additionalParam)
          );
          const sortedValidationsResults = validationsResults?.sort((m) =>
            m.isValid ? 1 : -1
          );
          if (sortedValidationsResults && sortedValidationsResults.length > 0) {
            updatedFormData[key] = sortedValidationsResults[0];
          }
          return validationsResults?.every((x) => x.isValid);
        } else return true;
      })
      .every((x) => x);
    setFormData(updatedFormData);
    return result;
  };

  const onChange = (
    targetVal: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name } = e.target;
    formData[name] = {
      ...formData[name],
      value: targetVal,
      isValid: true,
      message: '',
    };

    const state = {
      ...formData,
      [name]: { ...formData[name] },
    };
    setFormData(state);
  };

  const resetValidationStates = () => {
    // make a copy of everything in state
    const state = JSON.parse(JSON.stringify(formData));
    /*
        loop through each item in state and if it's safe to assume that only
        form values have an 'isValid' property, we can use that to reset their
        validation states and keep their existing value property. This process
        makes it easy to set all validation states on form inputs in case the number
        of fields on our form grows in the future.
        */
    Object.keys(state).forEach((key) => {
      if (state[key]) {
        state[key].isValid = true;
        state[key].message = '';
      }
    });
    Object.keys(formData).forEach((key) => (formData[key] = state[key]));
    setFormData(state);
  };

  const renderField = (
    name: string,
    label: string,
    type: FieldType,
    readonly?: boolean,
    placeholder?: string,
    tooltip?: string
  ) => {
    return (
      <FormGroup className="items mb-fontWeight-regular">
        {tooltip && tooltip.length > 0 ? (
          <BCTooltip title={tooltip}>
            <span className="mb-fontSize-xs">
              {label}{' '}
              <i className="fa fa-question-circle" aria-hidden="true"></i>
            </span>
          </BCTooltip>
        ) : (
          <label
            htmlFor={`inputFor${name}`}
            className="col-form-label mb-fontSize-xs translate"
          >
            {label}
          </label>
        )}
        <div>{_renderFieldInput(name, type, readonly, placeholder)}</div>
        <div className="help-block">
          {!formData[name]?.isValid && (
            <BCError>
              {formData[name]?.message
                ? t
                  ? t(formData[name]?.message)
                  : formData[name]?.message
                : ''}
            </BCError>
          )}
        </div>
      </FormGroup>
    );
  };

  const _renderFieldInput = (
    name: string,
    type: FieldType,
    readonly?: boolean,
    placeholder?: string
  ) => {
    const groupClass = classNames({
      'mb-has-error': formData[name] && !formData[name].isValid,
    });
    switch (type) {
      case FIELDS_TYPES.Password:
      case FIELDS_TYPES.Text:
      case FIELDS_TYPES.Email:
      default:
        return (
          <BCTextbox
            className={`form-control ${groupClass}`}
            name={name}
            type={type.toLowerCase()}
            value={(formData[name] && formData[name].value) || ''}
            onChange={(targetVal, e) => onChange(targetVal, e)}
            placeholder={placeholder}
            disabled={readonly}
          />
        );
    }
  };

  const onDroplistSelectionChange = (name: string, selectedKey?: string) => {
    const state = {
      ...formData,
      [name]: {
        ...formData[name],
        value: selectedKey,
        isValid: true,
        message: '',
      } as FormData,
    };
    setFormData(state);
  };

  const renderDroplistField = (
    name: string,
    label: string,
    options: BCDropListOptionsType[],
    placeholder?: string,
    tooltip?: string,
    lang?: string
  ) => {
    return (
      <FormGroup className="mb-fontWeight-regular">
        {tooltip && tooltip.length > 0 ? (
          <BCTooltip title={tooltip}>
            <label
              htmlFor={`inputFor${name}`}
              className="col-form-label mb-fontSize-xs translate"
            >
              {label}{' '}
              <i className="fa fa-question-circle" aria-hidden="true"></i>
            </label>
          </BCTooltip>
        ) : (
          <label
            htmlFor={`inputFor${name}`}
            className="col-form-label mb-fontSize-xs translate"
          >
            {label}
          </label>
        )}
        <div>
          <BCDropList
            className="fullSize"
            selected={formData[name] && formData[name].value}
            onSelectOption={(selectedKey) =>
              onDroplistSelectionChange(name, selectedKey)
            }
            options={options}
            placeholder={placeholder}
          />
        </div>
        <div className="help-block con-center">
          {!formData[name]?.isValid && (
            <BCError>
              {formData[name]?.message
                ? t
                  ? t(formData[name]?.message)
                  : formData[name]?.message
                : ''}
            </BCError>
          )}
        </div>
      </FormGroup>
    );
  };

  return [
    formData,
    {
      setFieldValue,
      validateField,
      onChange,
      resetValidationStates,
      renderField,
      _renderFieldInput,
      onDroplistSelectionChange,
      renderDroplistField,
      validateForm,
      setFieldError
    },
  ];
};

export default useBCForm;
