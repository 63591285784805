import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export type SetParam<T> = (value: T) => void;

// Setter, this sets the key=value pair in the url
function useSetQueryParam<T>(parameterKey: string) {
  const history = useHistory();
  const location = useLocation();

  return useCallback(
    (value: T) => {

      console.log("called");
      const urlParams = new URLSearchParams(location.search);
      urlParams.set(
        parameterKey,
        typeof value === 'object'
          ? JSON.stringify(value)
          : value as string
      );

      history.replace({ pathname: location.pathname, search: urlParams.toString() }); 
    },
    [parameterKey]
  );
}

function getUrlQueryParam<K>(
  key: string,
  urlSearchParams: URLSearchParams,
  initialValue?: K
) {
  const value = urlSearchParams.get(key);
  let parsedValue: K | undefined;

  if (value) {
    // JSON.parse the value from the URL
    try {
      parsedValue = JSON.parse(value as string);
    } catch (_) {
      parsedValue = value as K;
    }

    return parsedValue;
  }

  return initialValue;
}

function useInitialQueryParam<T>(
  setParam: SetParam<T>,
  initialValue: T | undefined,
  parameterKey: string
) {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const value = urlParams.get(parameterKey);

    if (value || !initialValue) {
      return;
    }

    setParam(initialValue);
  }, [setParam, initialValue, parameterKey]);
}

/**
 * useUrlState
 * --------------
 * const [addType, setAddType] = useUrlState("keyName");
 * addType is the value of the key=value pair in the url
 * setAddType is a function that sets the value of the key=value pair in the url
 */

export function useUrlState<K>(
  key: string,
  initialValue?: K
): [K | undefined, SetParam<K>] {
  const { search } = useLocation();
  const setParam = useSetQueryParam<K>(key);
  useInitialQueryParam(setParam, initialValue, key);
  const parsedValue = getUrlQueryParam<K>(key, new URLSearchParams(search), initialValue);

  return [parsedValue, setParam];
}
