import React from 'react';
import styled from '@emotion/styled';
import './bccheckbox.scss';

/* eslint-disable-next-line */
export interface BCCheckboxProps {
  className?: string,
  checked: boolean,
  onChange?: () => any,
  label: string,
}

const Checkbox = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;
`;
const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
  padding:0;
  margin:0;
  z-index:3;
`;

export const BCCheckbox = (props: BCCheckboxProps) => {
  return (
    <Checkbox className={"checkbox-style " + props.className}>
      <CheckboxInput type="checkbox" checked={props.checked} onChange={props.onChange} />
      <span className="translate">{props.label}</span>
      <span className="checkmark primary-border">
        <svg className="primary-fill" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" /></svg>
      </span>
    </Checkbox>
  );
};

export default BCCheckbox;
