import styled from '@emotion/styled';
import Color from '../constants/Color';
import IconType from '../constants/IconType';

export enum IconSize {
  XXL = 72,
  XL = 42,
  L = 28,
  M = 18,
  S = 14,
}

export type IconProps = {
  icon: IconType;
  size?: IconSize;
  color?: Color;
  className?: string;
  dataQA?: string;
};

type Without<T, K> = Pick<T, Exclude<keyof T, K>>;

const StyledIcon = styled.i<Without<IconProps, 'icon'>>`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  font-size: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  color: ${(props) => props.color};
  &.hoverBgColor:hover {
    svg {
      fill: ${Color.PRIMARY60};
    }
  }
`;

export function BCIcon({
  icon,
  size = IconSize.M,
  color = Color.PRIMARY,
  className,
  dataQA,
}: IconProps) {
  return (
    <StyledIcon
      data-qa={dataQA}
      className={`${icon} ${className}`}
      size={size}
      color={color}
    ></StyledIcon>
  );
}
