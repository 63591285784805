import { ReactElement } from 'react';
import { AvatarContentProps } from '../avatar';
import IconType from '../constants/IconType';
import { TooltipPlacement } from '../tooltip';

export type DropdownListItemValue = string | number;

export enum DropdownListSelectionMode {
  NONE = 'none',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
}

export enum DropdownPosition {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  TOP_LEFT = 'topLeft',
}
export enum DropdownType {
  TRANSPARENT = 'transparent',
  OUTLINED = 'outlined',
  SECONDARY = 'secondary',
  EDITOR = 'editor',
  GENERATOR = 'generator',
  DARK = 'dark',
}

export type DropdownListItemContent = {
  icon?: IconType | ReactElement;
  avatar?: AvatarContentProps;
  text?: string;
  description?: string | null;
  component?: ReactElement;
};

export type DropdownListItemType = {
  premium?: boolean;
  value?: DropdownListItemValue;
  isSeparator?: boolean;
  selected?: boolean;
  content?: DropdownListItemContent;
  disabled?: boolean;
  tooltipText?: string;
  tooltipTextPlacement?: TooltipPlacement;
  showTooltipOnDisabled?: boolean;
};

export type HeaderItemsProps = {
  onItemClick?: () => void;
  content: DropdownListItemContent;
};

export type FooterItemsProps = {
  onItemClick?: () => void;
  content: DropdownListItemContent;
};
