import gql from 'graphql-tag';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { dconfigClient } from '../../../clients/dconfigClient';
import { FoldersTreeQueryResult } from '../../../models/folder';
import { FilesQueryResult } from '../../../models/file';

export const foldersTreeQuery = gql`
  query foldersTree($rootPath: String) {
    foldersTree(rootPath: $rootPath)
      @rest(
        type: "FoldersTree"
        path: "/DConfig/IOServicesAPI/getFoldersTree?RootPath={args.rootPath}"
        method: "GET"
      ) {
        id,
        text,
        state,
        children,
        obj {
          Path
        }
    }
  }
`;

export const useFoldersTreeQuery = (rootPath: string) =>
useQuery<FoldersTreeQueryResult>(foldersTreeQuery, {
    fetchPolicy: 'no-cache',
    client: dconfigClient,
    variables: { rootPath },
  });


export const filesQuery = gql`
  query files($path: String) {
    files(path: $path)
      @rest(
        type: "Files"
        path: "/DConfig/IOServicesAPI/getFiles?Path={args.path}"
        method: "GET"
      ) {
        Name,
        Id,
        Path,
        Type,
        Length,
        CreationDate,
        LastModifiedDate,
    }
  }
`;

export const useFilesQuery = () =>
  useLazyQuery<FilesQueryResult>(filesQuery, {
    fetchPolicy: 'no-cache',
    client: dconfigClient,
  });

export const createFolderMutation = gql`
  mutation ($body: JSON!) {
    createFolder(body: $body)
      @rest(
        type: "CreateFolderType"
        path: "/DConfig/IOServicesAPI/createFolder"
        method: "POST"
        bodyKey: "body"
      ) {
      result
      message
    }
  }
`;

export const useCreateFolderMutation = () =>
  useMutation(createFolderMutation, {
    client: dconfigClient,
  });

export const deleteFolderMutation = gql`
  mutation ($path: String) {
    deleteFolder(path: $path)
      @rest(
        type: "DeleteFolderType"
        path: "/DConfig/IOServicesAPI/deleteFolder?Path={args.path}"
        method: "DELETE"
      ) {
      result
      message
    }
  }
`;

export const useDeleteFolderMutation = () =>
  useMutation(deleteFolderMutation, {
    client: dconfigClient,
  });

export const createFileMutation = gql`
  mutation ($path: String!, $file: Upload!) {
    createFolder(path: $path, file: $file)
      @rest(
        type: "CreateFolderType"
        path: "/DConfig/IOServicesAPI/createFile?Path={args.path}"
        method: "POST"
        bodyKey: "file",
        bodySerializer: "fileEncode"
      ) {
      result
      message
    }
  }
`;

export const useCreateFileMutation = () =>
  useMutation(createFileMutation, {
    client: dconfigClient,
  });

export const deleteFileMutation = gql`
  mutation ($path: String) {
    deleteFile(path: $path)
      @rest(
        type: "DeleteFileType"
        path: "/DConfig/IOServicesAPI/deleteFile?Path={args.path}"
        method: "DELETE"
      ) {
      result
      message
    }
  }
`;

export const useDeleteFileMutation = () =>
  useMutation(deleteFileMutation, {
    client: dconfigClient,
  });
