import gql from 'graphql-tag';
import { dconfigClient, tokenClient } from '../../../clients/dconfigClient';
import { auth } from '../../../services/auth';

export const login = gql`
  mutation ($body: JSON!) {
    login(body: $body)
      @rest(
        type: "LoginType"
        path: "/token"
        method: "POST"
        bodyKey: "body"
      ) {
      access_token
      username
      userId
    }
  }
`;
export const loginOptions: any = {
  options: {
    client: tokenClient,
  },
  props: ({
    ownProps,
    mutate,
  }: {
    ownProps: unknown;
    mutate: (payload: unknown) => void;
  }) => ({
    async login(userName: string, password: string, grantType = 'password') {
      try {
        const payload = {
          variables: {
            body: {
              username: userName,
              password: password,
              grant_type: grantType,
            },
          },
        };
        const result = await mutate(payload);
        // @ts-expect-error will be fixed later
        return Promise.resolve(result.data.login);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
};

export const register = gql`
  mutation ($body: JSON!) {
    register(body: $body)
      @rest(
        type: "RegisterType"
        path: "/Membership/bcRegister"
        method: "POST"
        bodyKey: "body"
      ) {
      result
      message
    }
  }
`;
export const registerOptions: any = {
  options: {
    client: dconfigClient,
  },
  props: ({
    ownProps,
    mutate,
  }: {
    ownProps: unknown;
    mutate: (payload: unknown) => void;
  }) => ({
    async register(
      FirstName: string,
      LastName: string,
      UserName: string,
      Email: string,
      Password: string,
      ConfirmPassword: string
    ) {
      try {
        const payload = {
          variables: {
            body: {
              FirstName,
              LastName,
              UserName,
              Email,
              Password,
              ConfirmPassword,
              RoleName: 'Powered Instances Owners',
            },
          },
        };
        const result = await mutate(payload);
        // @ts-expect-error will be fixed later
        return Promise.resolve(result.data.register);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
};

export const forgetPassword = gql`
  mutation ($body: JSON!) {
    forgetPassword(body: $body)
      @rest(
        type: "ForgetPasswordType"
        path: "/Membership/bcForgetPassword"
        method: "POST"
        bodyKey: "body"
      ) {
      result
      message
    }
  }
`;
export const forgetPasswordOptions: any = {
  options: {
    client: dconfigClient,
  },
  props: ({
    ownProps,
    mutate,
  }: {
    ownProps: unknown;
    mutate: (payload: unknown) => void;
  }) => ({
    async forgetPassword(Email: string, CustomResetPasswordUrl: string) {
      try {
        const payload = {
          variables: {
            body: { Email, CustomResetPasswordUrl },
          },
        };
        const result = await mutate(payload);
        // @ts-expect-error  will be fixed later
        return Promise.resolve(result.data.forgetPassword);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
};

export const resetPassword = gql`
  mutation ($body: JSON!) {
    resetPassword(body: $body)
      @rest(
        type: "ResetPasswordType"
        path: "/Membership/resetPasswordThroughEmail"
        method: "POST"
        bodyKey: "body"
      ) {
      result
      message
    }
  }
`;
export const resetPasswordOptions: any = {
  options: {
    client: dconfigClient,
  },
  props: ({
    ownProps,
    mutate,
  }: {
    ownProps: unknown;
    mutate: (payload: unknown) => void;
  }) => ({
    async resetPassword(
      UserId: string,
      Token: string,
      Password: string,
      ConfirmPassword: string
    ) {
      try {
        const payload = {
          variables: { body: { UserId, Token, Password, ConfirmPassword } },
        };
        const result = await mutate(payload);
        // @ts-expect-error  will be fixed later
        return Promise.resolve(result.data.resetPassword);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
};

export const userInfoQuery = gql`
  query {
    currentUser
      @rest(type: "User", path: "/Membership/getUser", method: "GET") {
      Id
      Email
      PhoneNumber
      AccessFailedCount
      UserName
    }
  }
`;

export const userInfoQueryOptions: any = {
  options: () => {
    return { variables: {}, client: dconfigClient };
  },
  name: 'user',
  props: ({
    user: { loading, currentUser, refetch },
  }: {
    user: { loading: boolean; currentUser: any; refetch: any };
  }) => {
    return {
      userInfo: currentUser,
      refetchUser: refetch,
      loadingUser: loading,
    };
  },
};
export interface DefinedUserProperty {
  Id: string;
  Value: string;
}
export const updateUserProfile = gql`
  mutation ($body: JSON!) {
    updateProfile(body: $body)
      @rest(
        type: "UpdateUserType"
        path: "/Membership/UpdateUsersFieldsValues"
        method: "PUT"
        bodyKey: "body"
      ) {
      id
    }
  }
`;
export const updateUserProfileOptions: any = {
  options: {
    client: dconfigClient,
  },
  props: ({ mutate }: { mutate: (payload: unknown) => void }) => ({
    async updateProfile(
      Id: string,
      DefinedUserProporties: DefinedUserProperty[]
    ) {
      try {
        const payload = { variables: { body: { Id, DefinedUserProporties } } };
        const result = await mutate(payload);
        // @ts-expect-error will be fixed later
        return Promise.resolve(result.data.updateProfile);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
};

export const updateUserPassword = gql`
  mutation ($body: JSON!) {
    updateUserPassword(body: $body)
      @rest(
        type: "UpdateUserPasswordType"
        path: "/Membership/ResetPassword"
        method: "PUT"
        bodyKey: "body"
      ) {
      result
      message
    }
  }
`;
export const updateUserPasswordOptions: any = {
  options: {
    client: dconfigClient,
  },
  props: ({ mutate }: { mutate: (payload: unknown) => void }) => ({
    async updatePassword(
      UserName: string,
      Password: string,
      ConfirmPassword: string
    ) {
      try {
        const payload = {
          variables: { body: { Password, ConfirmPassword, UserName } },
        };
        const result = await mutate(payload);
        // @ts-expect-error will be fixed later
        return Promise.resolve(result.data.updateUserPassword);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
};

export const inviteUser = gql`
  mutation ($body: JSON!) {
    inviteUser(body: $body)
      @rest(
        type: "InviteUserType"
        path: "/Membership/bcInvite"
        method: "POST"
        bodyKey: "body"
      ) {
      result
      message
    }
  }
`;
export const inviteUserOptions: any = {
  options: {
    client: dconfigClient,
  },
  props: ({
    ownProps,
    mutate,
  }: {
    ownProps: unknown;
    mutate: (payload: unknown) => void;
  }) => ({
    async inviteUser(Email: string) {
      try {
        const payload = {
          variables: {
            body: { Email, RoleName: 'Powered Instances Owners', },
          },
        };
        const result = await mutate(payload);
        // @ts-expect-error  will be fixed later
        return Promise.resolve(result.data.inviteUser);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
};

export const removeUserFromCompany = gql`
  mutation ($companyId: String!, $userId: String!) {
    removeUserFromCompany(companyId: $companyId, userId: $userId)
      @rest(
        type: "DeleteCompanyUsersType"
        path: "/Membership/deleteCompanyUsers?Id={args.companyId}&UserId={args.userId}"
        method: "DELETE"
      ) {
      result
      message
    }
  }
`;
export const removeUserFromCompanyOptions: any = {
  options: {
    client: dconfigClient,
  },
  props: ({ mutate }: { mutate: (payload: unknown) => void }) => ({
    async removeUserFromCompany(userId: string) {
      try {
        const payload = { variables: { companyId: auth.getContext(), userId } };
        const result = await mutate(payload);
        // @ts-expect-error will be fixed later
        return Promise.resolve(result.data.removeUserFromCompany);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
};
