export enum Color {
  INHERIT = 'inherit',
  // UI COLORS
  PRIMARY = '#006466',
  PRIMARY_LIGHTER = '#95D5B2',
  PRIMARY_DARKER = '#065A60',
  SECONDARY = '#4e4f4f',
  SECONDARY_LIGHT = '#9b9b9b',
  SECONDARY_LIGHT_HOVER = '#191919',
  LIGHT_COLOR = '#ccdbd5',
  DANGER_COLOR = '#b52e2e',
  SUCCESS_COLOR = '#2eb583',
  CONTROLS_COLOR = '#fff',
  FONT_COLOR = '#000',

  ACCENT10 = 'rgba(89, 111, 255, 0.1)',

  // UI COLORS with transparency
  PRIMARY90 = 'rgba(0, 100, 102, 0.9)',
  PRIMARY80 = 'rgba(0, 100, 102, 0.8)',
  PRIMARY60 = 'rgba(0, 100, 102, 0.6)',
  PRIMARY40 = 'rgba(0, 100, 102, 0.4)',
  PRIMARY30 = 'rgba(0, 100, 102, 0.3)',
  PRIMARY20 = 'rgba(0, 100, 102, 0.2)',
  PRIMARY10 = 'rgba(0, 100, 102, 0.1)',
  PRIMARY05 = 'rgba(0, 100, 102, 0.05)',
  PRIMARY_LIGHTER90 = 'rgba(149, 213, 178, 0.9)',
  PRIMARY_LIGHTER80 = 'rgba(149, 213, 178, 0.8)',
  PRIMARY_LIGHTER60 = 'rgba(149, 213, 178, 0.6)',
  PRIMARY_LIGHTER40 = 'rgba(149, 213, 178, 0.4)',
  PRIMARY_LIGHTER30 = 'rgba(149, 213, 178, 0.3)',
  PRIMARY_LIGHTER20 = 'rgba(149, 213, 178, 0.2)',
  PRIMARY_LIGHTER10 = 'rgba(149, 213, 178, 0.1)',
  PRIMARY_LIGHTER05 = 'rgba(149, 213, 178, 0.05)',
  SECONDARY90 = 'rgb(106, 107, 131, 0.9)',
  SECONDARY80 = 'rgb(106, 107, 131, 0.8)',
  SECONDARY70 = 'rgb(106, 107, 131, 0.7)',
  SECONDARY60 = 'rgb(106, 107, 131, 0.6)',
  SECONDARY50 = 'rgb(106, 107, 131, 0.5)',
  SECONDARY40 = 'rgb(106, 107, 131, 0.4)',
  SECONDARY30 = 'rgb(106, 107, 131, 0.3)',
  SECONDARY20 = 'rgb(106, 107, 131, 0.2)',
  SECONDARY10 = 'rgb(106, 107, 131, 0.1)',
  SECONDARY05 = 'rgb(106, 107, 131, 0.05)',

  GREY_LIGHT_BORDER = '#e4e4e4',
  GREY_BORDER = '#D9D9D9',
  GREY_TEXT_COLOR = '#727272',

  PRIMARY_DARKER80 = 'rgb(6, 90, 96, 0.8)',

  // Creative Contrast
  DAGOBAH_FOREST = '#003333',
  CHROME_ORANGE = '#FF9900',
  CHROME_ORANGE20 = 'rgba(255, 153, 0, 0.2)',
  // Energetic Contrast
  RED_HOT_HOOD = '#FF3300',
  MR_WOLF = '#330033',
  // Energetic Contrast with transparency
  RED_HOT_HOOD50 = 'rgba(255,51,0,0.5)',
  // Techy Contrast
  COLD_FUSION = '#6666FF',
  BLUEBERRY = '#330066',
  // OTHER COLORS
  HOT_MAGENTA = '#FF3366',
  BURNING_ORANGE = '#FF6600',
  LEMON_YELLOW = '#FFCC00',
  LASER_YELLOW = '#FFFF00',
  KRYPTONITE_GREEN = '#CCFF00',
  GREEN = '#66FF66',
  DARK_GREEN = '#148643',
  CRAZY_CYAN = '#66FFFF',
  EXOPLANET_BLUE = '#66CCFF',
  DARK_BLUE = '#457578',
  LIGHT_BLUE = '#e0f3e8',
  PURPLE = '#9999FF',
  PURPLE_RAIN = '#9966FF',
  VIOLET = '#CC66FF',
  AEROSMITH_PINK = '#FF66FF',
  WARNING_DOWNLOAD = 'rgba(255, 153, 0, 0.1)',
  GREY_WHITE = '#f0f1f5',
  SLIDER_DOT = '#dededf',
  ASSETS_PLAN_COLOR = '#35354C',
}

export default Color;
