import { TextSize } from './TextSize';

export const LineHeight = {
    [TextSize.XXS]: '16px',
    [TextSize.XS]: '18px',
    [TextSize.S]: '22px',
    [TextSize.M]: '28px',
    [TextSize.L]: '30px',
    [TextSize.XL]: '32px',
    [TextSize.XXL]: '36px',
};

export default LineHeight;
