export enum IconType {
  home = 'home',
  color = 'color',
  colorPalette = 'colorPalette',
  group = 'group',
  user = 'fa fa-user-circle-o',
  users = 'users',
  folderMinus = 'folderMinus',
  folderPlus = 'folderPlus',
  close = 'fa fa-times',
  chevronDown = 'fa fa-chevron-down',
  chevronUpSmall = 'chevronUpSmall',
  chevronDownSmall = 'chevronDownSmall',
  userPlus = 'userPlus',
  volume2 = 'volume2',
  layout = 'layout',
  deleteTrash = 'deleteTrash',
  moneySign = 'moneySign',
  more = 'more',
  moreVertical = 'moreVertical',
  circleSlash = 'circleSlash',
  checkmarkChecked = 'checkmarkChecked',
  checkmarkPartial = 'checkmarkPartial',
  checkmarkUnchecked = 'checkmarkUnchecked',
  checkmarkUncheckedEmpty = 'checkmarkUncheckedEmpty',
  checkmark = 'checkmark',
  chevronRightSmall = 'fa fa-chevron-right',
  chevronLeftRight = 'fa fa-chevron-left',
  back = 'back',
  name = 'name',
  flag = 'flag',
  globe = 'globe',
  search = 'search',
  settings = 'fa fa-sliders',
  grid = 'grid',
  columns = 'columns',
  set = 'set',
  setIndividual = 'setIndividual',
  star = 'star',
  starFull = 'starFull',
  edit = 'edit',
  edit2 = 'edit2',
  edit3 = 'edit3',
  info = 'fa fa-info',
  visibleNot = 'visibleNot',
  pin45 = 'pin45',
  pin45Full = 'pin45Full',
  share1 = 'share1',
  share2 = 'share2',
  chevronLeft = 'chevronLeft',
  help = 'fa fa-question-circle',
  mail = 'mail',
  upload = 'upload',
  menu = 'menu',
  arrowUp = 'arrowUp',
  arrowDown = 'arrowDown',
  clock = 'clock',
  sortAlphabetical = 'sortAlphabetical',
  duplicate = 'duplicate',
  userX = 'userX',
  forward = 'forward',
  save = 'save',
  copy = 'copy',
  google = 'google',
  facebook = 'facebook',
  twitter = 'twitter',
  youtube = 'youtube',
  pinterest = 'pinterest',
  instagram = 'instagram',
  linkedin = 'linkedin',
  yahoo = 'yahoo',
  paper = 'paper',
  rolesAndRights = 'rolesAndRights',
  customBranding = 'customBranding',
  brandControl = 'brandControl',
  settingsSliders = 'settingsSliders',
  minus = 'minus',
  download = 'download',
  html5 = 'html5',
  mp4 = 'mp4',
  pdf = 'pdf',
  amp = 'amp',
  gif = 'gif',
  warning = 'fa fa-exclamation-triangle',
  visible = 'visible',
  lowQuality = 'lowQuality',
  mediumQuality = 'mediumQuality',
  highQuality = 'highQuality',
  maximumQuality = 'maximumQuality',
  optimizedQuality = 'optimizedQuality',
  layoutVertical = 'layoutVertical',
  plusBig = 'plusBig',
  brandKit = 'brandKit',
  premiumBadge = 'premiumBadge',
  premium = 'premium',
  creditCard = 'creditCard',
  moveToFolder1 = 'moveToFolder1',
  loadingSpinner = 'loadingSpinner',
  layoutSquare = 'layoutSquare',
  layoutHorizontal = 'layoutHorizontal',
  lock = 'lock',
  smartphone = 'smartphone',
  etsy = 'etsy',
  soundcloud = 'soundcloud',
  twitch = 'twitch',
  carousel = 'carousel',
  brochure = 'brochure',
  businessCard = 'businessCard',
  card = 'card',
  flyer = 'flyer',
  invitation = 'invitation',
  letterhead = 'letterhead',
  magazineCover = 'magazineCover',
  poster = 'poster',
  certificate = 'certificate',
  resume = 'resume',
  logo = 'logo',
  bookOpen = 'bookOpen',
  book = 'book',
  albumCover = 'albumCover',
  slideshow = 'slideshow',
  fhdSlideshow = 'fhdSlideshow',
  video = 'video',
  customSize = 'customSize',
  zoomToFit = 'zoomToFit',
  zoomOneToOne = 'zoomOneToOne',
  scaledPreview = 'scaledPreview',
  playFull = 'playFull',
  play = 'play',
  stop = 'stop',
  pause = 'pause',
  resetPlayPositionSkipBack = 'resetPlayPositionSkipBack',
  plusWide = 'plusWide',
  exit = 'exit',
  resize = 'resize',
  vector = 'vector',
  unlink = 'unlink',
  text = 'text',
  target = 'target',
  tag = 'tag',
  button = 'button',
  refresh = 'refresh',
  playWithCircle = 'playWithCircle',
  pauseWithCircle = 'pauseWithCircle',
  replay = 'replay',
  chevronRight = 'chevronRight',
  feedback = 'fa fa-comment',
  list = 'list',
  repeat = 'repeat',
  refreshccw = 'refreshccw',
  penTool = 'penTool',
  cornerLeftUpArrowEnabled = 'cornerLeftUpArrowEnabled',
  cornerRightBottomArrow = 'cornerRightBottomArrow',
  command = 'command',
  control = 'control',
  letterF = 'letterF',
  videoPlay = 'videoPlay',
  attachment = 'attachment',
  comment = 'comment',
  commentPin2 = 'commentPin2',
  commentNew = 'commentNew',
  adTag = 'adTag',
  aA = 'aA',
  calendar = 'calendar',
  sad = 'sad',
  app = 'app',
  union = 'union',
  bgRemove = 'bgRemove',
  thumbsUp = 'thumbsUp',
  thumbsDown = 'thumbsDown',
  key = 'key',
  approval = 'approval',
  psdFile = 'psdFile',
  letterT = 'letterT',
  letterT2 = 'letterT2',
  show = 'fa fa-eye',
  hide = 'fa fa-eye-slash',
  exclamation = 'fa fa-exclamation-circle',
  folder = 'fa fa-folder-o',
  file = 'fa fa-file-o',
  levelUp = 'fa fa-level-up',
  plus = 'fa fa-plus-square-o',
  image = 'fa fa-file-image-o',
  externalLink = 'fa fa-external-link',
  link = 'fa fa-link',
  logout = 'fa fa-sign-out',
  roadmap = 'fa fa-road',
  changelog = 'fa fa-refresh',
  timeoutline = 'fa fa-clock-o',
  profile = 'fa fa-user',
  tags = 'fa fa-tags'
}

export default IconType;
