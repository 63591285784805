import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import Dropzone from 'react-dropzone';

export interface BCDropZoneProps {
  onDrop: (acceptedFiles: File[], rejectedFiles: File[]) => void;
  onDragEnter: () => void;
  onDragLeave: () => void;
  multiple?: boolean;
  acceptedFileTypes: string[];
  maxSize?: number;
}

const Container = styled.div`
  width: 100% !important;
  height: 100% !important;

  > div {
    width: 100% !important;
    height: 100% !important;
  }

  .dropzoneContainer {
    background-color: transparentize($color-dark, 0.9);
    height: 0;
    opacity: 0;
    position: absolute;
    transition: opacity 0.15s, height 0s 0.15s;
    width: 100%;
    z-index: 3;
    &.dropzoneVisible {
      height: 100%;
      opacity: 1;
      transition: opacity 0.15s;
    }
  }

  .dropzoneContent {
    background-color: $color-stage-white;
    border-radius: 6px;
    bottom: 57px;
    box-shadow: 0 20px 60px 0 transparentize($color-dark, 0.9);
    height: 175px;
    left: 50%;
    padding-top: 5px;
    position: absolute;
    transform: translateX(-50%);
    width: 462px;
    z-index: 3;
  }

  .uploadIcon {
    height: 85px;
    text-align: center;

    > svg {
      fill: $color-blue;
      height: 95px;
      width: 95px;
    }
  }

  .text {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;

    &.uploadImageText {
      color: $color-dark;
      font-size: 28px;
      font-weight: bold;
      height: 33px;
      margin: 8px 0;
    }

    &.uploadMultipleText {
      color: transparentize($color-dark, 0.6);
      font-size: 14px;
      font-weight: normal;
      height: 16px;
      margin-top: 0px;
    }
  }

  .enter {
    bottom: 0;
    opacity: 0;
    &.enterActive {
      bottom: 57px;
      opacity: 1;
      transition: all 0.3s;
    }
  }

  .leave {
    bottom: 57px;
    opacity: 1;
    transition: all 0.3s;
    &.leaveActive {
      bottom: 0;
      opacity: 0;
    }
  }

  .dropzone {
    display: none;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 4;
    &.dropzoneVisible {
      display: block;
    }
  }
`;

export const BCDropZone = ({
  children,
  onDrop,
  onDragEnter,
  onDragLeave,
  multiple = false,
  acceptedFileTypes,
  maxSize = 1e7, // 10MB max size
}: PropsWithChildren<BCDropZoneProps>) => {
  return (
    <Container>
      <Dropzone
        onDrop={onDrop as any}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        multiple={multiple}
        accept={acceptedFileTypes as any}
        maxSize={maxSize}
      >
        {children}
      </Dropzone>
    </Container>
  );
};

export default BCDropZone;
