import React, { forwardRef, ReactNode } from 'react';
import { Scrollbars, positionValues } from 'react-custom-scrollbars';
import styled from '@emotion/styled';
import Color from '../constants/Color';

export enum ScrollAreaSize {
  S = 'S',
  L = 'L',
  XL = 'XL',
}

type ScrollAreaSizeKeys = keyof typeof ScrollAreaSize | string;

interface ScrollAreaSizeInterface {
  size: number;
}

type ScrollAreaValuesType = {
  [key in ScrollAreaSizeKeys]: ScrollAreaSizeInterface;
};

export const ScrollAreaValues: ScrollAreaValuesType = {
  [ScrollAreaSize.S]: {
    size: 6,
  },
  [ScrollAreaSize.L]: {
    size: 10,
  },
  [ScrollAreaSize.XL]: {
    size: 16,
  },
};

export type ScrollAreaProps = {
  size: ScrollAreaSize;
  children: ReactNode;
  autoHide?: boolean;
  /** used if autoHide is set to true */
  autoHideTimeout?: number;
  /** used if autoHide is set to true */
  autoHideDuration?: number;
  autoHeight?: boolean;
  /** used if autoHeight is set to true */
  autoHeightMin?: number | string;
  /** used if autoHeight is set to true */
  autoHeightMax?: number | string;
  hideHorizontalTrack?: boolean;
  hideVerticalTrack?: boolean;
  renderScrollbarHorizontal?: any;
  renderThumbHorizontal?: any;
  onScrollFrame?: (values: positionValues) => void;
  onUpdate?: (values: positionValues) => void;
  onScrollStart?: () => void;
  onScrollStop?: () => void;
  onScroll?: () => void;
  verticalTrackRightPosition?: number;
  rtl?: boolean;
};

const Wrapper = styled.div<{ rtl: boolean }>`
  height: 100%;
  > div > div:first-of-type {
    ${({ rtl }) => (rtl ? 'margin-left: -17px; margin-right:0px !Important;' : '')};
  }
`;

const Track = styled.div<ScrollAreaProps>`
  background-color: transparent;
  border-radius: 8px;
  position: absolute;
  transition: opacity 0.1s ease-in-out;
`;

const HorizontalTrack = styled(Track)`
  right: 0;
  bottom: 0;
  left: 0;
`;

const VerticalTrack = styled(Track)<ScrollAreaProps>`
  bottom: 0;
  top: 0;
  ${({ size }) => (size === ScrollAreaSize.XL ? 'padding: 4px;' : '')}
  ${({ rtl, verticalTrackRightPosition }) =>
    rtl
      ? `left: ${verticalTrackRightPosition || 0}px`
      : `right: ${verticalTrackRightPosition || 0}px`}
`;

const Thumb = styled.div`
  background-color: ${Color.CONTROLS_COLOR};
  cursor: default;
  position: relative;
  border-radius: inherit;
  transition: background-color 0.1s ease-in-out;
  :hover,
  :active {
    background-color: ${Color.CONTROLS_COLOR};
  }
`;

export const BCScrollArea = forwardRef<Scrollbars, ScrollAreaProps>(
  (
    {
      size,
      children,
      autoHide = false,
      autoHideTimeout = undefined,
      autoHideDuration = undefined,
      autoHeight = false,
      autoHeightMin = undefined,
      autoHeightMax = undefined,
      onScrollFrame = undefined,
      onUpdate = undefined,
      hideHorizontalTrack = false,
      hideVerticalTrack = false,
      verticalTrackRightPosition = undefined,
      onScrollStart = undefined,
      onScrollStop = undefined,
      onScroll = undefined,
      rtl = false,
    }: ScrollAreaProps,
    ref
  ) => {
    function getHorizontalTrack({ style, ...props }: any) {
      return (
        <HorizontalTrack
          size={size}
          children={children}
          {...props}
          style={{ ...style, height: `${ScrollAreaValues[size].size}px` }}
        />
      );
    }
    function getVerticalTrack({ style, ...props }: any) {
      const trackRtlStyle = rtl ? { left: '2px', right: 'auto' } : {};
      return (
        <VerticalTrack
          size={size}
          children={children}
          verticalTrackRightPosition={verticalTrackRightPosition}
          rtl={rtl}
          {...props}
          style={{
            ...style,
            width: `${ScrollAreaValues[size].size}px`,
            ...trackRtlStyle,
          }}
        />
      );
    }

    function getThumb(props: any) {
      return <Thumb {...props} />;
    }

    return (
      <Wrapper rtl={rtl} className="test">
        <Scrollbars
          onScrollFrame={onScrollFrame}
          onUpdate={onUpdate}
          autoHide={autoHide}
          autoHideTimeout={autoHideTimeout}
          autoHideDuration={autoHideDuration}
          autoHeight={autoHeight}
          autoHeightMin={autoHeightMin}
          autoHeightMax={autoHeightMax}
          renderTrackHorizontal={
            hideHorizontalTrack ? undefined : getHorizontalTrack
          }
          renderTrackVertical={hideVerticalTrack ? undefined : getVerticalTrack}
          renderThumbHorizontal={getThumb}
          renderThumbVertical={getThumb}
          onScrollStart={onScrollStart}
          onScrollStop={onScrollStop}
          onScroll={onScroll}
          ref={ref}
        >
          {children}
        </Scrollbars>
      </Wrapper>
    );
  }
);
