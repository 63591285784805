import React, { PropsWithChildren } from 'react';
import { FlexAlignItems, BCFlexBox, FlexDirection } from '../flexBox';

export type VerticalLayoutProps = {
    distance?: string;
    alignItems?: FlexAlignItems;
    marginTop?: string;
    marginRight?: string;
    marginBottom?: string;
    marginLeft?: string;
    overflow?: string;
    width?: string;
    dataTestid?: string;
    minWidth?: string;
    textAlign?: string;
};

/**
 * The Vertical Layout component is a simple way to align multiple elements vertically.
 *
 * By default the contained controls are not wrapped.
 * If you want more sophisticated layout options, consider GridLayout or FlexBox components.
 */
export const BCVerticalLayout = ({
    distance,
    alignItems,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    overflow,
    children,
    width,
    dataTestid,
    minWidth,
    textAlign,
}: PropsWithChildren<VerticalLayoutProps>) => (
    <BCFlexBox
        direction={FlexDirection.COLUMN}
        alignItems={alignItems}
        distance={distance}
        marginTop={marginTop}
        marginRight={marginRight}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        overflow={overflow}
        width={width}
        dataTestid={dataTestid}
        minWidth={minWidth}
        textAlign={textAlign}
    >
        {children}
    </BCFlexBox>
);
