import { MouseEvent, ReactElement, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import Color from '../constants/Color';
import { IconButton } from '../iconButton';
import IconType from '../constants/IconType';
import { keyCode } from '../constants/KeyCode';
import { BCScrollArea, ScrollAreaSize } from '../scrollArea';
import { IconSize } from '../icon/bcicon';

export enum OverlayContentPosition {
  CenterScreen,
  RightSide,
  LeftSide,
}

export type BCOverlayProps = {
  children: ReactElement[] | ReactElement;
  showCloseIcon?: boolean;
  closeOnOverlayClick?: boolean;
  closeOnEsc?: boolean;
  onCloseHandler: () => void;
  stopPropagationOnClick?: boolean;
  presumedHeight?: number;
  zIndex?: number;
  overlayContentPosition?: OverlayContentPosition;
};

const BCOverlayWrapper = styled.div<{ zIndex?: number }>`
  bottom: 0;
  cursor: auto;
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${Color.SECONDARY30};
  backdrop-filter: blur(2px);
  z-index: ${({ zIndex }) => (zIndex ? zIndex : '1101')};
  margin: auto;
  display: flex;
  align-items: center;
`;

const BCOverlayContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const PositionContainer = styled.div<{
  overlayContentPosition?: OverlayContentPosition;
}>`
  display: flex;
  height: 100vh;
  justify-content: center;
 justify-content: ${({ overlayContentPosition }) => {
    switch (overlayContentPosition) {
      case OverlayContentPosition.CenterScreen:
        return 'center';
      case OverlayContentPosition.LeftSide:
        return 'left';
      default:
        return 'right';
    }
  }};
`;

const Position = styled.div<{ presumedHeight?: string }>`
  display: flex;
  align-self: center;
  height: ${({ presumedHeight }) =>
    presumedHeight ? `${presumedHeight}` : 'auto'};
`;

const CloseIconWrapper = styled.div`
  margin: 0 8px 0 8px;
  z-index: 5;
`;

/**
 * This component is used to add an overlay in the application
 */
export function BCOverlay({
  children,
  showCloseIcon = true,
  closeOnOverlayClick = true,
  closeOnEsc = true,
  onCloseHandler,
  stopPropagationOnClick = true,
  presumedHeight,
  zIndex,
  overlayContentPosition = OverlayContentPosition.CenterScreen,
}: BCOverlayProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const onKeyPressEvent = (ev: any) => {
      if (closeOnEsc && ev.keyCode === keyCode.ESC) {
        ev.stopPropagation();
        onCloseHandler();

      }
    };
    window.addEventListener('keydown', onKeyPressEvent);
    return () => {
      window.removeEventListener('keydown', onKeyPressEvent);
    };
  }, [closeOnEsc, onCloseHandler]);

  let activeElement: Element | null = null;

  const onOverlayMouseDown = (ev: MouseEvent) => {
    ev.stopPropagation();
    activeElement = document.activeElement;
  };

  const onOverlayClick = (ev: MouseEvent) => {
    if (stopPropagationOnClick) {
      ev.stopPropagation();
    }
    const clickedInsidePopin =
      containerRef.current &&
      containerRef.current.contains(ev.target as Element);
    if (clickedInsidePopin) {
      return;
    }

    if (closeOnOverlayClick) {
      onCloseHandler();
    }
  };

  const closeIcon = showCloseIcon ? (
    <CloseIconWrapper>
      <IconButton
        onClick={onCloseHandler}
        icon={IconType.close}
        size={IconSize.L}
      />
    </CloseIconWrapper>
  ) : null;

  return (
    <BCOverlayWrapper
      onClick={onOverlayClick}
      onMouseDown={onOverlayMouseDown}
      zIndex={zIndex}
    >
      <BCOverlayContent>
        <PositionContainer overlayContentPosition={overlayContentPosition}>
          <Position
            presumedHeight={
              presumedHeight
                ? `${presumedHeight.toString()}px`
                : overlayContentPosition === OverlayContentPosition.RightSide ||
                  overlayContentPosition === OverlayContentPosition.LeftSide
                  ? '100%'
                  : undefined
            }
          >
            {overlayContentPosition === OverlayContentPosition.RightSide &&
              closeIcon ||
              overlayContentPosition === OverlayContentPosition.LeftSide &&
              closeIcon}
            <div ref={containerRef}>{children}</div>
            {overlayContentPosition === OverlayContentPosition.CenterScreen &&
              closeIcon}
          </Position>
        </PositionContainer>
      </BCOverlayContent>
    </BCOverlayWrapper>
  );
}
