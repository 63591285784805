import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { LangService } from '@bcrumbs.net/bc-api';
import AppWrapper from './appWrapper';
import 'bootstrap-4-grid/css/grid.min.css';
import 'react-tippy/dist/tippy.css';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://6aa91d18e0b443d3ae3aab6dd9dceee8@o4505459717570560.ingest.sentry.io/4505459720257536',
  environment: environment.production ? 'production' : 'development',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'i.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // Avast extension error
    '_avast_submit',
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'ResizeObserver loop limit exceeded',
    'Cannot redefine property: ethereum',
    'Blocked a frame with origin',
    'msDiscoverChatAvailable',
    'ResizeObserver',
    'NetFunnel is not defined',
  ],
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    /^safari-web-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i,
    // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    'recaptcha/releases',
    'userscript',
    'translate_http',
    'maps/api/js',
    'recorder-v2',
    'user-guiding',
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (!environment.production) {
  require('../../../packages/bc-assets/styles/index.scss');
  require('../../../packages/bc-assets/styles/skin.scss');
  require('../../../packages/bc-assets/styles/skeleton.scss');
  require('../../../packages/bc-assets/icons/flaticon.css');
  require('../../../packages/bc-assets/icons/icons.scss');
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

LangService.init();

ReactDOM.render(<AppWrapper />, document.getElementById('root'));
