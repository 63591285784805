export enum DropdownSize {
    S = 'S',
    M = 'M',
    L = 'L',
    XL = 'XL',
}

export enum DropdownListWidth {
    XXS = 90,
    XS = 120,
    S = 190,
    M = 220,
    L = 240,
    XL = 360,
}