import { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import Color from '../constants/Color';
import { BCText } from '../text/bctext';
import IconType from '../constants/IconType';
import { BCIcon } from '../icon/bcicon';
import TextWeight from '../constants/TextWeight';
import TextSize from '../constants/TextSize';
import { BCTooltip } from '../tooltip';
import { extractInitialsFromText } from '../utils/extractInitialsFromText';

export enum AvatarSize {
  S = 28,
  M = 36,
  L = 42,
  XXL = 72,
}

const AvatarContainer = styled.div<AvatarProps>`
  border-radius: 50%;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  flex-shrink: 0;
  ${({ url, color, text }) =>
    url
      ? css`
          background: url(${url}) no-repeat center;
          background-size: cover;
        `
      : css`
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${(text && color) || Color.PRIMARY10};
          overflow: hidden;
        `};
`;

export type AvatarContentProps = {
  url?: string | null;
  text?: string;
  color?: string;
  textColor?: Color;
};

export type AvatarProps = AvatarContentProps & {
  size?: AvatarSize;
  className?: string;
  showTooltip?: boolean;
};

export function BCAvatar({
  url,
  text,
  color,
  size = AvatarSize.M,
  className,
  textColor = Color.CONTROLS_COLOR,
  showTooltip = false,
}: AvatarProps) {
  let child: ReactElement<any, any> | undefined = undefined;

  if (!url) {
    child = text ? (
      <BCText
        weight={TextWeight.MEDIUM}
        size={size === AvatarSize.XXL ? TextSize.S : TextSize.M}
        color={textColor}
      >
        {extractInitialsFromText(text)}
      </BCText>
    ) : (
      <BCIcon icon={IconType.user} />
    );
  }

  return showTooltip ? (
    <BCTooltip title={text}>
      <AvatarContainer
        className={className}
        url={url}
        color={color}
        size={size}
        text={text}
      >
        {child}
      </AvatarContainer>
    </BCTooltip>
  ) : (
    <AvatarContainer
      className={className}
      url={url}
      color={color}
      size={size}
      text={text}
    >
      {child}
    </AvatarContainer>
  );
}
