import GraphemeSplitter from 'grapheme-splitter';

const splitGraphemes = (word: string): string[] => {
  const splitter = new GraphemeSplitter();

  const splitWord: string[] = splitter.splitGraphemes(word);

  return splitWord;
};

export function extractInitialsFromText(name?: string) {
  if (!name) {
    return '';
  }

  const splitName = name
    .trim()
    .toUpperCase()
    .replace(/\s{2,}/g, ' ')
    .split(' ');

  if (splitName.length <= 1) {
    const splitWord = splitGraphemes(splitName[0]);

    if (splitWord.length > 1) {
      return `${splitWord[0]}${splitWord[1]}`;
    }

    return splitWord[0];
  }

  const splitFirstWord = splitGraphemes(splitName[0]);

  const splitSecondWord = splitGraphemes(splitName[1]);

  return splitFirstWord[0] + splitSecondWord[0];
}
