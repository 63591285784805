import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

export const LOCAL_STORAGE_I18N_STRING = 'I18N_SELECTION';

const systemDefaultLang = 'en';
const init = () => {
  const currentLng =
    localStorage.getItem(LOCAL_STORAGE_I18N_STRING) || systemDefaultLang;

  i18n
    .use(Backend) // load translation using http
    // .use(LanguageDetector) // detect user language
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      lng: currentLng,
      fallbackLng: systemDefaultLang,
      ns: 'translation',
      interpolation: {
        escapeValue: false,
      },
      load: 'languageOnly',
      backend: {
        loadPath: '/assets/locales/{{lng}}/{{ns}}.json'
      },
    });
};

const changeLang = (lang: Languages) => {
  localStorage.setItem(LOCAL_STORAGE_I18N_STRING, lang);
  i18n.changeLanguage(lang);
};

const getLang = () => {
  return localStorage.getItem(LOCAL_STORAGE_I18N_STRING) ?? systemDefaultLang;
};

export const LangService = {
  init,
  changeLang,
  getLang,
};

export enum Languages {
  EN = 'en',
  AR = 'ar',
  TR = 'tr',
}
