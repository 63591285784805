import decode from 'jwt-decode';
import { isAfter } from 'date-fns';
import { dconfigClient } from '../../clients/dconfigClient';

type STORES_TYPES = 'localStorage' | 'sessionStorage';
type Storage = STORES_TYPES;
type TokenKey = string;
type ContextKey = string;
type UserInfoKey = string;

const CONTEXT_KEY = 'ContextId';
const TOKEN_KEY = 'token';
const USER_INFO = 'userInfo';

const APP_PERSIST_STORES_TYPES: Array<STORES_TYPES> = [
  'localStorage',
  'sessionStorage',
];

type Token = {
  exp: number;
};

type User = {
  name?: string;
  surname?: string;
  username?: string;
  email?: string;
  id: string;
};

const parse = JSON.parse;
const stringify = JSON.stringify;

/*
  auth object
  -> store "TOKEN_KEY"
  - default storage is "localStorage"
  - default token key is 'token'
 */
export const auth = {
  // /////////////////////////////////////////////////////////////
  // TOKEN
  // /////////////////////////////////////////////////////////////

  /**
   * get token from localstorage
   *
   * @param {'localStorage' | 'sessionStorage'} [fromStorage='localStorage'] specify storage
   * @param {any} [tokenKey=TOKEN_KEY]  optionnal parameter to specify a token key
   * @returns {string} token value
   */
  getToken(
    fromStorage: Storage = APP_PERSIST_STORES_TYPES[0],
    tokenKey: TokenKey = TOKEN_KEY
  ) {
    if (typeof window === 'undefined') {
      return null;
    }

    // localStorage:
    if (fromStorage === APP_PERSIST_STORES_TYPES[0]) {
      return (
        (window.localStorage && window.localStorage.getItem(tokenKey)) || null
      );
    }
    // sessionStorage:
    if (fromStorage === APP_PERSIST_STORES_TYPES[1]) {
      return (
        (window.sessionStorage && window.sessionStorage.getItem(tokenKey)) ||
        null
      );
    }
    // default:
    return null;
  },

  /**
   * set the token value into localstorage (managed by localforage)
   *
   * @param {string} [value=''] token value
   * @param {'localStorage' | 'sessionStorage'} [toStorage='localStorage'] specify storage
   * @param {any} [tokenKey='token'] token key
   * @returns {boolean} success/failure flag
   */
  setToken(
    value = '',
    toStorage: Storage = APP_PERSIST_STORES_TYPES[0],
    tokenKey: TokenKey = TOKEN_KEY
  ) {
    if (typeof window === 'undefined') {
      throw Error('Token should not be set from backend side.');
    }

    if (!value || value.length <= 0) {
      return;
    }
    // localStorage:
    if (toStorage === APP_PERSIST_STORES_TYPES[0]) {
      if (window.localStorage) {
        window.localStorage.setItem(tokenKey, value);
      }
    }
    // sessionStorage:
    if (toStorage === APP_PERSIST_STORES_TYPES[1]) {
      if (window.sessionStorage) {
        window.sessionStorage.setItem(tokenKey, value);
      }
    }
  },

  /**
   * check
   * - if token key contains a valid token value (defined and not an empty value)
   * - if the token expiration date is passed
   *
   *
   * Note: 'isAuthenticated' just checks 'tokenKey' on store (localStorage by default or sessionStorage)
   *
   * You may think: 'ok I just put an empty token key and I have access to protected routes?''
   *    -> answer is:  YES^^
   * BUT
   * -> : your backend will not recognize a wrong token so private data or safe and you protected view could be a bit ugly without any data.
   *
   * => ON CONCLUSION: this aim of 'isAuthenticated'
   *    -> is to help for a better "user experience"  (= better than displaying a view with no data since server did not accept the user).
   *    -> it is not a security purpose (security comes from backend, since frontend is easily hackable => user has access to all your frontend)
   *
   * @param {'localStorage' | 'sessionStorage'} [fromStorage='localStorage'] specify storage
   * @param {any} [tokenKey=TOKEN_KEY] token key
   * @returns {bool} is authenticed response
   */
  isAuthenticated(
    fromStorage: Storage = APP_PERSIST_STORES_TYPES[0],
    tokenKey: TokenKey = TOKEN_KEY
  ): boolean {
    if (typeof window === 'undefined') {
      return false;
    }

    // localStorage:
    if (fromStorage === APP_PERSIST_STORES_TYPES[0]) {
      if (window.localStorage && window.localStorage.getItem(tokenKey)) {
        return true;
      } else {
        return false;
      }
    }
    // sessionStorage:
    if (fromStorage === APP_PERSIST_STORES_TYPES[1]) {
      if (window.sessionStorage && window.sessionStorage.getItem(tokenKey)) {
        return true;
      } else {
        return false;
      }
    }
    // default:
    return false;
  },

  /**
   * delete token
   *
   * @param {any} [tokenKey='token'] token key
   * @returns {bool} success/failure flag
   */
  clearToken(
    storage: Storage = APP_PERSIST_STORES_TYPES[0],
    tokenKey: TokenKey = TOKEN_KEY
  ): boolean {
    if (typeof window === 'undefined') {
      return false;
    }

    // localStorage:
    if (window.localStorage && window.localStorage[tokenKey]) {
      window.localStorage.removeItem(tokenKey);
      return true;
    }
    // sessionStorage:
    if (window.sessionStorage && window.sessionStorage[tokenKey]) {
      window.sessionStorage.removeItem(tokenKey);
      return true;
    }

    return false;
  },

  //TODO: Its not working for now while the token is not JWT token
  /**
   * return expiration date from token
   *
   * @param {string} encodedToken - base 64 token received from server and stored in local storage
   * @returns {date | null} returns expiration date or null id expired props not found in decoded token
   */
  getTokenExpirationDate(encodedToken: string): Date {
    if (typeof window === 'undefined') {
      return new Date(0);
    }

    if (!encodedToken) {
      return new Date(0); // is expired
    }

    const token = decode<Token>(encodedToken);
    if (!token.exp) {
      return new Date(0); // is expired
    }
    const expirationDate = new Date(token.exp * 1000);
    return expirationDate;
  },

  //TODO: Its not working for now while the token is not JWT token
  /**
   *
   * tell is token is expired (compared to now)
   *
   * @param {string} encodedToken - base 64 token received from server and stored in local storage
   * @returns {bool} returns true if expired else false
   */
  isExpiredToken(encodedToken: string): boolean {
    const expirationDate = this.getTokenExpirationDate(encodedToken);
    const rightNow = new Date();
    const isExpiredToken = isAfter(rightNow, expirationDate);

    return isExpiredToken;
  },

  // /////////////////////////////////////////////////////////////
  // USER_INFO
  // /////////////////////////////////////////////////////////////
  /**
   * get user info from localstorage
   *
   * @param {'localStorage' | 'sessionStorage'} [fromStorage='localStorage'] specify storage
   * @param {any} [userInfoKey='userInfo']  optionnal parameter to specify a token key
   * @returns {string} token value
   */
  getUserInfo(
    fromStorage: Storage = APP_PERSIST_STORES_TYPES[0],
    userInfoKey: UserInfoKey = USER_INFO
  ) {  
    if (typeof window === 'undefined') {
      return undefined;
    }

    // localStorage:
    if (fromStorage === APP_PERSIST_STORES_TYPES[0]) {
      const userString = window.localStorage.getItem(userInfoKey);
      return userString && parse(userString);
    }
    // sessionStorage:
    if (fromStorage === APP_PERSIST_STORES_TYPES[1]) {
      const userString = window.sessionStorage.getItem(userInfoKey);
      return userString && parse(userString);
    }
    // default:
    return null;
  },

  /**
   * set the userInfo value into localstorage
   *
   * @param {object} [value=''] token value
   * @param {'localStorage' | 'sessionStorage'} [toStorage='localStorage'] specify storage
   * @param {any} [userInfoKey='userInfo'] token key
   * @returns {boolean} success/failure flag
   */
  setUserInfo(
    value: User,
    toStorage: Storage = APP_PERSIST_STORES_TYPES[0],
    userInfoKey: UserInfoKey = USER_INFO
  ) {
    if (typeof window === 'undefined') {
      throw Error('User info should not be set from backend side.');
    }

    if (!value) {
      return;
    }
    // localStorage:
    if (toStorage === APP_PERSIST_STORES_TYPES[0]) {
      if (window.localStorage) {
        window.localStorage.setItem(userInfoKey, stringify(value));
      }
    }
    // sessionStorage:
    if (toStorage === APP_PERSIST_STORES_TYPES[1]) {
      if (window.sessionStorage) {
        window.sessionStorage.setItem(userInfoKey, stringify(value));
      }
    }
  },

  updateUserInfo(
    value: User,
    toStorage: Storage = APP_PERSIST_STORES_TYPES[0],
    userInfoKey: UserInfoKey = USER_INFO
  ): any {
    const user = this.getUserInfo();
    user.name = value.name;
    user.surname = value.surname;
    this.setUserInfo(user);
  },
  /**
   * delete userInfo
   *
   * @param {string} [userInfoKey='userInfo'] token key
   * @returns {bool} success/failure flag
   */
  clearUserInfo(userInfoKey: UserInfoKey = USER_INFO): any {
    if (typeof window === 'undefined') {
      throw Error('User info should not be cleared from backend side.');
    }

    // localStorage:
    if (window.localStorage && window.localStorage[userInfoKey]) {
      window.localStorage.removeItem(userInfoKey);
    }
    // sessionStorage:
    if (window.sessionStorage && window.sessionStorage[userInfoKey]) {
      window.sessionStorage.removeItem(userInfoKey);
    }
  },

  // /////////////////////////////////////////////////////////////
  // COMMON
  // /////////////////////////////////////////////////////////////

  /**
   * forget me method: clear all
   * @returns {bool} success/failure flag
   */
  clearAllAppStorage() {
    if (typeof window === 'undefined') {
      throw Error('App storage should not be cleared from backend side.');
    }

    if (window.localStorage) {
      window.localStorage.clear();
    }
    if (window.sessionStorage) {
      window.sessionStorage.clear();
    }
    dconfigClient.clearStore();
  },

  // /////////////////////////////////////////////////////////////
  // Context
  // /////////////////////////////////////////////////////////////
  /**
   * set the context value into localstorage (managed by localforage)
   *
   * @param {string} [value=''] context value
   * @param {'localStorage' | 'sessionStorage'} [toStorage='localStorage'] specify storage
   * @param {any} [tokenKey='token'] token key
   * @returns {boolean} success/failure flag
   */
  setContext(
    value = '',
    toStorage: Storage = APP_PERSIST_STORES_TYPES[0],
    contextKey: ContextKey = CONTEXT_KEY
  ) {
    if (typeof window === 'undefined') {
      throw Error('Context should not be set from backend side.');
    }

    if (!value || value.length <= 0) {
      return;
    }
    // localStorage:
    if (toStorage === APP_PERSIST_STORES_TYPES[0]) {
      if (window.localStorage) {
        window.localStorage.setItem(contextKey, value);
      }
    }
    // sessionStorage:
    if (toStorage === APP_PERSIST_STORES_TYPES[1]) {
      if (window.sessionStorage) {
        window.sessionStorage.setItem(contextKey, value);
      }
    }
  },
  /**
   * get context from localstorage
   *
   * @param {'localStorage' | 'sessionStorage'} [fromStorage='localStorage'] specify storage
   * @param {any} [tokenKey=TOKEN_KEY]  optionnal parameter to specify a token key
   * @returns {number} token value
   */
  getContext(
    fromStorage: Storage = APP_PERSIST_STORES_TYPES[0],
    contextKey: ContextKey = CONTEXT_KEY
  ) {
    if (typeof window === 'undefined') {
      return undefined;
    }

    // localStorage:
    if (fromStorage === APP_PERSIST_STORES_TYPES[0]) {
      return parseInt(
        (window.localStorage && window.localStorage.getItem(contextKey)) || ''
      );
    }
    // sessionStorage:
    if (fromStorage === APP_PERSIST_STORES_TYPES[1]) {
      return parseInt(
        (window.sessionStorage && window.sessionStorage.getItem(contextKey)) ||
          ''
      );
    }
    // default:
    return null;
  },
};

export default auth;
