export const BCValidations = {
  // eslint-disable-next-line
  emailValidation:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
  passwordValidation: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/i,
  urlValidation:
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/i,
  phoneNumberValidation:
    /^([+]|)(90(?:| )5[\d]{2}|5[\d]{2}|9(?:| )5[\d]{2}|5[\d]{2}|0(?:| )5[\d]{2}|5[\d]{2})(?:| )([\d]{3})(?:| )([\d]{2})(?:| )([\d]{2})$/i,
  turkishIdentity: /^[0-9]{11}$/i,
  licencePlate:
    /^(0[1-9]|[1-7][0-9]|8[01])(([A-Z])(\d{4,5})|([A-Z]{2})(\d{3,4})|([A-Z]{3})(\d{2}))$/i,
  domain: /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/i
};

export const BCDefaultValidationMessages = {
  emailMessage: 'Not a valid email address',
  domainMessage: 'Not a valid domain',
  passwordMessage:
    'Password should be at least 8 characters, have at least one capital and small characters and one number',
  confirmPasswordMessage: 'Confirm password must equal password',
  nameMessage: 'The name must be at least 2 charachters',
  emptyMessage: 'The field cannot be empty',
  urlMessage: 'Not a valid url',
  usernameMessage: 'The username must be at least 2 charachters',
};

export enum BCValidationTypes {
  NOT_EMPTY = 'NOT_EMPTY',
  EMAIL = 'EMAIL',
  PASSWORD = 'PASSWORD',
  CONFIRM_PASSWORD = 'CONFIRM_PASSWORD',
  NAME_2_LETTERS = 'NAME_2_LETTERS',
  TURKISH_IDENTITY = 'TURKISH_IDENTITY',
  DOMAIN = 'DOMAIN'
}
