export interface Model {
  Id: number;
  Name: string;
  ViewFields: ModelField[];
}

export interface ModelField {
  Id: number;
  Name: string;
  Type: ModelFieldsTypes;
  EnumId: number;
}

export interface ModelQueryResult {
  viewTypes: Model[];
}

export class ModelUtilities {
  static ConvertModelsListToFieldsMap(
    models: Model[]
  ): Map<number, ModelField> {
    const fieldsMap = new Map<number, ModelField>();
    if (models && models.length > 0) {
      models.forEach(model => {
        if (model.ViewFields && model.ViewFields.length > 0) {
          model.ViewFields.forEach(vf => {
            fieldsMap.set(vf.Id, vf);
          });
        }
      });
    }
    return fieldsMap;
  }
}

export enum ModelFieldsTypes {
  String = "String",
  StringML = "String - Multiple Lines",
  RichTextBox = "Rich Text Box",
  Date = "Date",
  PredefinedList = "Predefined List",
  PredefinedListCheckboxes = "Predefined List - Checkboxes",
  PredefinedListRadioButtons = "Predefined List - Radio Buttons",
  PredefinedListFilterSelect = "Predefined List - Filter/Select",
  AutoIncrementNumber = "Auto Increment Number",
  ContentUrl = "Content Url",
  Boolean = "Boolean",
  Number = "Number",
  Image = "Image",
  MultipleImages = "Multiple Images",
  JSON = "JSON"
}