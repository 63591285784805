import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { dqueryClient } from '../../../clients/dqueryClient';

export const portalThemeConfig = gql`
  query ($domain: String!) {
    configuration(type: "portalTheme", domain: $domain)
  }
`;

export const usePortalThemeConfig = (domain: string) =>
  useQuery(portalThemeConfig, {
    fetchPolicy: 'cache-first',
    client: dqueryClient,
    variables: { domain },
  });
