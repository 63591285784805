function loadParams() {
  if (typeof window !== 'undefined') {
    const list = window.location.search.substring(1).split('&');
    const m: any = {};
    for (let i = 0; i < list.length; i++) {
      const indexOf = list[i].indexOf('=');
      if (indexOf >= 0) {
        m[list[i].substring(0, indexOf)] = decodeURIComponent(
          list[i].substring(indexOf + 1)
        );
      }
    }
    return m;
  }

  return {};
}

function initBackendsLocations() {
  params = loadParams();
  api = params['api'];
  if (typeof window !== 'undefined' && window.location) {
    currentUrl = window.location.href;
  }
  switch (api) {
    case 'prod':
      dBackend = 'https://query.bcrumbs.net';
      frontend = 'https://app.bcrumbs.net';
      apiBackend = 'https://api.bcrumbs.net';
      apiV2Backend = 'https://apiv2.bcrumbs.net';
      showcaseRenderer = 'https://test-showcase.bcrumbs.net';
      break;
    case 'test':
      dBackend = 'https://query.bcrumbs.net';
      frontend = 'https://dev.bcrumbs.net';
      apiBackend = 'https://api.bcrumbs.net';
      apiV2Backend = 'apiv2-dev.bcrumbs.net';
      showcaseRenderer = 'https://test-showcase.bcrumbs.net';
      break;
    case 'local':
      dBackend = 'https://localhost:44322';
      frontend = 'http://localhost:4200';
      apiBackend = 'http://localhost:6085';
      apiV2Backend = 'https://apiv2-dev.bcrumbs.net';
      showcaseRenderer = 'https://test-showcase.bcrumbs.net';
      break;
    default:
      if (currentUrl && currentUrl.indexOf('localhost') > -1) {
        dBackend = 'https://query.bcrumbs.net';
        frontend = 'http://localhost:4200';
        apiBackend = 'https://api.bcrumbs.net';
        apiV2Backend = 'https://apiv2.bcrumbs.net';
        showcaseRenderer = 'https://test-showcase.bcrumbs.net';
      } else if (currentUrl && currentUrl.indexOf('dconfig.com') > -1) {
        dBackend = 'https://query.bcrumbs.net';
        frontend = 'https://app.bcrumbs.net';
        apiBackend = 'https://api.bcrumbs.net';
        apiV2Backend = 'https://apiv2.bcrumbs.net';
        showcaseRenderer = 'https://test-showcase.bcrumbs.net';
      } else if (currentUrl && currentUrl.indexOf('dev.bcrumbs.net') > -1) {
        dBackend = 'https://query.bcrumbs.net';
        frontend = 'https://dev.bcrumbs.net';
        apiBackend = 'https://api.bcrumbs.net';
        apiV2Backend = 'https://apiv2.bcrumbs.net';
        showcaseRenderer = 'https://test-showcase.bcrumbs.net';
      } else {
        dBackend = 'https://query.bcrumbs.net';
        frontend = 'https://app.bcrumbs.net';
        apiBackend = 'https://api.bcrumbs.net';
        apiV2Backend = 'https://apiv2.bcrumbs.net';
        showcaseRenderer = 'https://test-showcase.bcrumbs.net';
      }

      break;
  }
}

let params;
let currentUrl: string;
let frontend;
let dBackend;
let apiBackend;
let apiV2Backend;
let showcaseRenderer;
let api;

initBackendsLocations();

export const appConfig = {
  dconfig: {
    networkInterface: apiBackend,
  },
  dquery: {
    networkInterface: dBackend + '/graphql',
  },
  showcase: {
    networkInterface: showcaseRenderer,
  },
  billing: {
    networkInterface: apiV2Backend + '/billing',
  },
  core: {
    networkInterface: apiV2Backend + '/core',
  },
  // sw path
  sw: {
    path: 'assets/sw.js',
  },
};

export default appConfig;
export { frontend, apiBackend, apiV2Backend };
