import { useState, useEffect, useCallback } from 'react';

type UsePollingProps = {
  action: () => any;  // The action to execute
  interval: number;  // Interval time in milliseconds
};

export const usePolling = ({ action, interval }: UsePollingProps) => {
  const [isPolling, setIsPolling] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const startPolling = useCallback(() => {
    if (!isPolling) {
      const id = setInterval(action, interval);
      setIntervalId(id);
      setIsPolling(true);
    }
  }, [action, interval, isPolling]);

  const stopPolling = useCallback(() => {
    if (intervalId) {
      clearInterval(intervalId);
      setIsPolling(false);
    }
  }, [intervalId]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        stopPolling();
      } else if (document.visibilityState === 'visible') {
        startPolling();
      }
    };

    // Listen for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Start polling when the component mounts
    startPolling();

    // Cleanup on unmount
    return () => {
      if (intervalId) {
        clearInterval(intervalId);  // Clear the interval when component unmounts
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange);  // Cleanup event listener
    };
  }, [interval, intervalId, startPolling, stopPolling]);

  return { isPolling };
};
