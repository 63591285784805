import { useState } from 'react';

/* eslint-disable-next-line */
export interface BCTextboxProps {
  className?: string;
  name?: string;
  onChange?: (val: string, event: any) => any;
  onKeyPress?: (ev: any) => any;
  onKeyDown?: (ev: any) => any;
  value: string;
  type: string;
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  autoCompleteList?: string[];
  onBlur?: () => any;
  onClick?: (ev: any) => any;
  onFocus?: (ev: any) => any;
}

export interface BCTextboxState {
  showAutoComplete: boolean;
}

export const BCTextbox = ({
  className='',
  name,
  onChange,
  onKeyPress,
  onKeyDown,
  onClick,
  value='',
  type,
  disabled=false,
  placeholder,
  maxLength,
  autoCompleteList,
  onBlur,
  onFocus,
}: BCTextboxProps) => {
  const [showAutoComplete, setShowAutoComplete] = useState(false);

  return (
    <div>
      <input
        className={className}
        onBlur={onBlur}
        name={name}
        onChange={(e) => {
          e.persist();
          const targetVal = e.target.value;
          let lastWord: string | null = null;
          if (targetVal && targetVal.length > 1) {
            const wordArrays = targetVal.split(' ');
            lastWord = wordArrays[wordArrays.length - 1];
          }
          let valueExistsInAutoComplete = false;
          if (targetVal === '#') {
            valueExistsInAutoComplete = true;
          }
          if (autoCompleteList && lastWord && lastWord.length >= 1) {
            autoCompleteList.forEach((val) => {
              if (
                val &&
                lastWord &&
                val.toUpperCase().indexOf(lastWord.toUpperCase()) >= 0
              ) {
                valueExistsInAutoComplete = true;
              }
            });
          }
          if (valueExistsInAutoComplete) {
            setShowAutoComplete(true);
            onChange && onChange(targetVal, e);
          } else {
            setShowAutoComplete(false);
            onChange && onChange(targetVal, e);
          }
        }}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        type={type}
        onClick={onClick}
        onFocus={onFocus}
      />
      {autoCompleteList && autoCompleteList.length && showAutoComplete ? (
        <div className="autoComplete">
          <ul>
            {autoCompleteList
              .filter(
                (val) =>
                  val
                    .toUpperCase()
                    .indexOf(
                      value
                        .split(' ')
                        [value.split(' ').length - 1].toUpperCase()
                    ) >= 0
              )
              .map((val) => (
                <li
                  onClick={() => {
                    setShowAutoComplete(false);
                    const words = value.split(' ');
                    words.pop();
                    const result = words.join(' ') + ' ' + val;
                    onChange && onChange(result.substring(1), undefined); // Substring for removing the begining space resulted from join
                  }}
                >
                  <span>{val}</span>
                </li>
              ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default BCTextbox;
