import { Color } from "@bcrumbs.net/bc-ui";

export const PARAMETERS = [];

export const BLUR_CONTAINER = 'root';
export const LOADING_COLOR = Color.PRIMARY;

export const HELPDESK_URL = "https://bcrumbs.featurebase.app/help";
export const CHANGELOG_URL = "https://bcrumbs.featurebase.app/changelog";
export const FEEDBACK_URL = "https://bcrumbs.featurebase.app/";
export const ROADMAP_URL = "https://bcrumbs.featurebase.app/roadmap";
