import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Tippy, { TippyProps } from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { TooltipPlacement } from './TooltipPlacement';
import TooltipTrigger from './TooltipTrigger';
import TextSize from '../constants/TextSize';
import TextWeight from '../constants/TextWeight';
import Color from '../constants/Color';

type TooltipTransientProps = {
  $minWidth?: number;
  borderRadius?: number;
  padding?: string;
};

export type TooltipProps = {
  title: string | ReactElement | null | undefined;
  placement?: TooltipPlacement;
  trigger?: TooltipTrigger;
  maxWidth?: number;
  minWidth?: number;
  children: ReactElement;
  offset?: number;
  distance?: number;
  delay?: number;
  borderRadius?: number;
  padding?: string;
  interactive?: boolean;
  className?: string;
};

const TippyTooltip = styled<React.FC<TippyProps & TooltipTransientProps>>(
  ({ borderRadius, padding, ...props }) => <Tippy {...props} />
)<TooltipTransientProps>`
  &.tippy-box {
    background-color: ${Color.SECONDARY};
    border-radius: ${({ borderRadius }) => `${borderRadius}px`};
    color: ${Color.LIGHT_COLOR};
    font-size: ${TextSize.XS}px;
    font-weight: ${TextWeight.MEDIUM};
    line-height: 16px;
    min-width: ${({ $minWidth }) => ($minWidth ? `${$minWidth}px` : 'auto')};

    .tippy-content {
      padding: ${({ padding }) => padding};
    }

    .tippy-arrow {
      color: ${Color.SECONDARY};
      height: 8px;
      width: 8px;
    }
  }

  &.tippy-box[data-placement^='top'] > .tippy-arrow:before {
    bottom: -4px;
    border-width: 4px 4px 0;
  }

  &.tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
    top: -4px;
    border-width: 0 4px 4px;
  }

  &.tippy-box[data-placement^='left'] > .tippy-arrow:before {
    border-width: 4px 0 4px 4px;
    right: -4px;
  }

  &.tippy-box[data-placement^='right'] > .tippy-arrow:before {
    left: -4px;
    border-width: 4px 4px 4px 0;
  }
`;

/**
 * Tooltip is a component which renders a popup tip containing text or custom content.
 */
export const BCTooltip = ({
  title,
  placement,
  trigger,
  children,
  maxWidth,
  minWidth,
  offset = 0,
  distance = 8,
  interactive = false,
  borderRadius = 6,
  padding = '6px 10px',
  delay = 100,
  className,
}: TooltipProps) =>
title ? (
    <TippyTooltip
      placement={placement || TooltipPlacement.TOP}
      content={title}
      offset={[offset, distance]}
      trigger={trigger || TooltipTrigger.HOVER}
      $minWidth={minWidth}
      maxWidth={maxWidth || 'auto'}
      duration={0}
      borderRadius={borderRadius}
      padding={padding}
      interactive={interactive}
      delay={delay}
      className={className}
    >
      {children}
    </TippyTooltip>
  ) : (
    children
  );

export * from './TooltipPlacement';
export * from './TooltipTrigger';
