import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import IconType from '../constants/IconType';
import { BCIcon, IconSize } from '../icon/bcicon';
import { Color } from '../constants/Color';
import { BCTooltip } from '../tooltip';

export type IconButtonProps = {
  onClick: () => void;
  size?: IconSize;
  icon: IconType;
  tooltipContent?: string;
  className?: string;
  color?: Color;
};

const IconButtonContainer = styled.div`
  display: inline-block;
  cursor: pointer;
`;

export function IconButton({
  onClick,
  icon,
  size = IconSize.M,
  tooltipContent,
  className,
  color = Color.CONTROLS_COLOR,
}: PropsWithChildren<IconButtonProps>) {
  const button = (
    <IconButtonContainer onClick={onClick} className={className}>
      <BCIcon color={color} size={size} icon={icon} />
    </IconButtonContainer>
  );

  return tooltipContent ? (
    <BCTooltip title={tooltipContent}>{button}</BCTooltip>
  ) : (
    button
  );
}
