import { useEffect } from 'react';
import Loader from 'react-loader-spinner';
import styled from '@emotion/styled';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { Color } from '../constants';

export interface LoadingProps {
  showBox?: boolean;
  blurContainer?: string;
  color?: string;
}

const LoadingContainer = styled.div`
  position: fixed;
  text-align: center;
  z-index: 1;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  filter: blur(0px);
  @media (max-width: 767px) {
    background: #fff;
  }
`;

const LoadingContent = styled.div`
  position: relative;
  background-color: none;
  padding: 20px;
  width: 60px;
  height: 60px;
  margin: auto;
  top: calc(50% - 60px);
  border-radius: 3px;
  text-align: center;
`;

export const BCLoading = ({
  showBox = true,
  blurContainer,
  color = Color.PRIMARY,
}: LoadingProps) => {

  useEffect(() => {
    if (blurContainer && document.getElementById(blurContainer)) {
      if (showBox) {
        document.getElementById(blurContainer)?.classList.add('blur');
      } else {
        document.getElementById(blurContainer)?.classList.remove('blur');
      }
    }
    return () => {
      if (blurContainer && document.getElementById(blurContainer)) {
        document.getElementById(blurContainer)?.classList.remove('blur');
      }
    };
  }, [showBox, blurContainer]);

  return showBox ? (
    <LoadingContainer>
      <LoadingContent>
        <Loader type="Puff" color={color} height={70} width={70} />
      </LoadingContent>
    </LoadingContainer>
  ) : null;
};

// export const withBCLoading = R.ifElse(R.prop('loading'), BCLoading);

export default BCLoading;
