export enum WhiteSpace {
    NORMAL = 'normal',
    NOWRAP = 'nowrap',
    PRE = 'pre',
    PRE_WRAP = 'pre-wrap',
    PRE_LINE = 'pre-line',
    BREAK_SPACES = 'break-spaces',
    INHERIT = 'inherit',
    INITIAL = 'initial',
    UNSET = 'unset',
}

export default WhiteSpace;
