import gql from 'graphql-tag';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { dconfigClient } from '../../../clients/dconfigClient';
import { showcaseRendererClient } from '../../../clients/showcaseRendererClient';
import { auth } from '../../../services/auth';
import { UsageRecordQueryResult } from '../../../models/usage';

export const showcaseConfigurationQuery = gql`
  query ($ContextId: Int) {
    showcaseConfiguration(Key: "ShowcaseConfig", ContextId: $ContextId)
      @rest(
        type: "ShowcaseConfig"
        path: "/DConfig/SettingsAPI/getCustomSettings?{args}"
        method: "GET"
      )
  }
`;

export const useShowcaseConfigurationQuery = () =>
  useQuery(showcaseConfigurationQuery, {
    fetchPolicy: 'cache-first',
    client: dconfigClient,
    variables: { ContextId: auth.getContext() },
  });

export const showcaseConfigurationQueryOptions: any = {
  options: () => {
    return { client: dconfigClient };
  },
  name: 'showcaseConfiguration',
  props: ({
    showcaseConfiguration: { loading, showcaseConfiguration, refetch },
  }: {
    showcaseConfiguration: {
      loading: boolean;
      showcaseConfiguration: any;
      refetch: any;
    };
  }) => {
    try {
      const config = JSON.parse(showcaseConfiguration);
      return {
        config,
        refetchConfig: refetch,
        loadingConfig: loading,
      };
    } catch (ex) {
      console.log('Failed to parse configuration');
      return {
        refetchConfig: refetch,
        loadingConfig: loading,
        config: undefined,
      };
    }
  },
};

export const menuConfigurationQuery = gql`
  query ($ContextId: Int) {
    menuConfiguration(Key: "MenuConfig", ContextId: $ContextId)
      @rest(
        type: "MenuConfig"
        path: "/DConfig/SettingsAPI/getCustomSettings?{args}"
        method: "GET"
      )
  }
`;
export const useMenuConfigurationQuery = () =>
  useQuery(menuConfigurationQuery, {
    fetchPolicy: 'cache-first',
    client: dconfigClient,
    variables: { ContextId: auth.getContext() },
  });


export const addDomain = gql`
  mutation ($rootContent: String!, $body: JSON!) {
    addDomain(rootContent: $rootContent, body: $body)
      @rest(
        type: "AddDomainType"
        path: "/DConfig/WebsiteContentAPI/addDomainToContent/{args.rootContent}"
        method: "PUT"
        bodyKey: "body"
      ) {
      result
      message
    }
  }
`;

export const addDomainOptions: any = {
  options: {
    client: dconfigClient,
  },
  props: ({
    ownProps,
    mutate,
  }: {
    ownProps: unknown;
    mutate: (payload: unknown) => void;
  }) => ({
    async addDomain(Domain: string, rootContent: string) {
      try {
        const payload = { variables: { body: { Domain }, rootContent } };
        const result = await mutate(payload);
        // @ts-expect-error will be fixed later
        return Promise.resolve(result.data.addDomain);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
};

export const removeDomain = gql`
  mutation ($rootContent: String!, $Domain: String!) {
    removeDomain(rootContent: $rootContent, Domain: $Domain)
      @rest(
        type: "RemoveDomainType"
        path: "/DConfig/WebsiteContentAPI/removeDomainFromContent/{args.rootContent}?Domain={args.Domain}"
        method: "DELETE"
        bodyKey: "body"
      ) {
      result
      message
    }
  }
`;

export const removeDomainOptions: any = {
  options: {
    client: dconfigClient,
  },
  props: ({
    ownProps,
    mutate,
  }: {
    ownProps: unknown;
    mutate: (payload: unknown) => void;
  }) => ({
    async removeDomain(Domain: string, rootContent: string) {
      try {
        const payload = { variables: { Domain, rootContent } };
        const result = await mutate(payload);
        // @ts-expect-error will be fixed later
        return Promise.resolve(result.data.removeDomain);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
};

export const useShowcaseTemplate = gql`
  mutation (
    $templateId: Int!
    $companyId: Int!
    $parentContentId: Int
    $path: String
  ) {
    useShowcaseTemplate(
      templateId: $templateId
      companyId: $companyId
      parentContentId: $parentContentId
      path: $path
    )
      @rest(
        type: "UseShowcaseTemplateType"
        path: "/DConfig/DConfigModule/duplicateShowcaseInstance?SourceCompanyContext={args.templateId}&DestinationCompanyContext={args.companyId}&DestinationContentId={args.parentContentId}&path={args.path}"
        method: "GET"
      ) {
      result
      message
    }
  }
`;

export const useShowcaseTemplateOptions: any = {
  options: {
    client: dconfigClient,
  },
  props: ({
    ownProps,
    mutate,
  }: {
    ownProps: unknown;
    mutate: (payload: unknown) => void;
  }) => ({
    async useShowcaseTemplate(
      templateId: number,
      parentContentId?: number,
      path?: string
    ) {
      try {
        const payload = {
          variables: {
            templateId,
            companyId: auth.getContext(),
            parentContentId,
            path,
          },
        };
        const result = await mutate(payload);
        // @ts-expect-error will be fixed later
        return Promise.resolve(result.data.useShowcaseTemplate);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
};

export const useShowcaseTemplateProgress = gql`
  query ($templateId: Int!, $companyId: Int!) {
    useShowcaseTemplateProgress(templateId: $templateId, companyId: $companyId)
      @rest(
        type: "UseShowcaseTemplateProgressType"
        path: "/DConfig/DConfigModule/getShowcaseInstanceProgress?SourceCompanyContext={args.templateId}&DestinationCompanyContext={args.companyId}"
        method: "GET"
      ) {
      result
      obj
    }
  }
`;

export const useShowcaseTemplateProgressQuery = (templateId: number) =>
  useLazyQuery(useShowcaseTemplateProgress, {
    fetchPolicy: 'no-cache',
    client: dconfigClient,
    variables: { templateId, companyId: auth.getContext() },
  });

export const updateTemplateSectionThumb = gql`
  query ($sectionId: Int!, $contextCompanyId: Int!, $value: String!) {
    updateTemplateSectionThumb(
      sectionId: $sectionId
      contextCompanyId: $contextCompanyId
      value: $value
    )
      @rest(
        type: "SectionsThumbsMap"
        path: "/api/Values/updateSectionThumbWithSpecialToken?sectionId={args.sectionId}&contextCompanyId={args.contextCompanyId}&value={args.value}&token=3de1e3c6-4b44-42de-b065-14308236b96c"
        method: "PUT"
      )
  }
`;

export const registeUsage = gql`
  query ($body: JSON!) {
    registerUsage(body: $body)
      @rest(
        type: "RegisterUsage"
        path: "/DConfig/PaymentsManager/recordShowcaseVisit"
        method: "POST"
        bodyKey: "body"
      ) {
      result
    }
  }
`;

export const useRegisterUsage = (body: { Domain: string }) =>
  useMutation(registeUsage, {
    client: dconfigClient,
    variables: { body },
  });

export const queryUsage = gql`
  query ($AccountId: String!) {
    queryUsage(AccountId: $AccountId)
      @rest(
        type: "Usage"
        path: "/DConfig/PaymentsManager/getShowcaseVisit?AccountId={args.AccountId}"
        method: "GET"
      ) {
      result
      object
      data {
        id
        object
        invoice
        livemode
        period {
          end
          start
        }
        subscription_item
        total_usage
      }
      has_more
      url
    }
  }
`;

export const useQueryUsage = (AccountId: string) =>
  useQuery<UsageRecordQueryResult>(queryUsage, {
    fetchPolicy: 'no-cache',
    client: dconfigClient,
    variables: { AccountId },
  });

export const querySectionThumb = gql`
  query ($sectionId: Int!, $templateId: Int!, $templateContextId: Int!) {
    querySectionThumb(
      sectionId: $sectionId
      templateId: $templateId
      templateContextId: $templateContextId
    )
      @rest(
        type: "SectionThumb"
        path: "/api/sectionThumb?sectionId={args.sectionId}&templateId={args.templateId}&templateContextId={args.templateContextId}"
        method: "GET"
      ) {
      text
    }
  }
`;

export const useQuerySectionThumb = (
  sectionId: number,
  templateId: number,
  templateContextId: number
) =>
  useQuery(querySectionThumb, {
    fetchPolicy: 'no-cache',
    client: showcaseRendererClient,
    variables: { sectionId, templateId, templateContextId },
  });
