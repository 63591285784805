import { Link } from 'react-router-dom';
import { BCTooltip, TooltipPlacement } from '../../../tooltip';
import { NavItem } from '../../types';
import { Color } from '../../../constants';

export type Props = NavItem & {
  onClick: (event: any, action?: string) => void;
  active: boolean;
};

export const LeftNavButton = ({
  link,
  label,
  onClick,
  active,
  icon,
  action,
  externalLink,
}: Props) => {
  return (
    <li className={active ? 'active' : ''}>
      {link ? (
        <Link
          to={link}
          onClick={(e) => onClick(e, action)}
          style={{ color: Color.GREY_WHITE }}
        >
          <BCTooltip title={label} placement={TooltipPlacement.RIGHT}>
            {typeof icon === 'string' ? <i className={icon}></i> : icon}
          </BCTooltip>
        </Link>
      ) : (
        <a
          href={externalLink}
          rel="noopener noreferrer"
          target="_blank"
          onClick={(e) => onClick(e, action)}
          style={{ color: Color.GREY_WHITE }}
        >
          <BCTooltip title={label} placement={TooltipPlacement.RIGHT}>
            {typeof icon === 'string' ? <i className={icon}></i> : icon}
          </BCTooltip>
        </a>
      )}
    </li>
  );
};

export default LeftNavButton;
