import { ReactElement, useEffect, useRef, useState } from 'react';
import { DropdownList } from './DropdownList';
import styled, { css } from 'styled-components';
import { BCIcon } from '../icon/bcicon';
import Color from '../constants/Color';
import IconType from '../constants/IconType';
import TextSize from '../constants/TextSize';
import TextWeight from '../constants/TextWeight';
import { LineHeight } from '../constants/LineHeight';
import { DropdownListWidth, DropdownSize } from '../constants';
import { AvatarSize, BCAvatar } from '../avatar';
import { DropdownListItemType, DropdownListItemValue, DropdownPosition, DropdownType, FooterItemsProps } from './types';

type DropdownButtonProps = {
  width?: number;
  disabled?: boolean;
};

type DropdownWrapperProps = {
  hide?: boolean;
  position?: DropdownPosition;
  width?: DropdownListWidth;
};

type SelectedOptionProps = {
  fontWeight?: TextWeight;
};

export type DropdownProps = DropdownButtonProps &
  SelectedOptionProps & {
    color?: Color;
    className?: string;
    error?: string;
    type: DropdownType;
    selectedValue?: DropdownListItemValue;
    items: DropdownListItemType[];
    maxVisibleItems?: number;
    listWidth?: DropdownListWidth;
    onItemClick: (value: DropdownListItemValue) => void;
    selectedState?: boolean;
    footerItems?: FooterItemsProps[] | null | undefined;
    fixedSelectedValue?: string | number | ReactElement | null;
    title?: string;
    titleTooltip?: string;
    useAvatar?: boolean;
    preventSelected?: boolean;
    disabled?: boolean;
    autoTopDownDropdownList?: boolean;
    dataTestid?: string;
    noHoverState?: boolean;
    parentBottomOffset?: number;
    size?: DropdownSize;
    dropdownPosition?: DropdownPosition;
    iconDistance?: number;
    onDropdownStateChange?: (options: { hidden: boolean }) => void;
    preventDefaultOnMouseDown?: boolean;
    selectedInputMask?: string;
    hideDropdownOnCollapse?: boolean;
  };

const DropdownWrapper = styled.div<DropdownWrapperProps>`
  ${({ position, width }) =>
    position
      ? `
        width: ${width ? `${width}px` : '100%'};
        position: absolute;
        ${getStyleForPosition(position)}`
      : ''};
  ${({ hide }) =>
    hide
      ? css`
          position: fixed;
          visibility: hidden;
        `
      : ''}
`;

const DropdownButton = styled.div<DropdownButtonProps>`
  align-items: center;
  border: 1px solid transparent;
  border-radius: 6px;
  display: flex;
  font-size: ${TextSize.S}px;
  height: 36px;
  justify-content: space-between;
  padding: 8px;
  width: ${({ width }) => (width ? `${width}px` : '100%')};

  &.${DropdownType.SECONDARY} {
    background: ${Color.PRIMARY10};
  }

  &.${DropdownType.EDITOR} {
    background-color: ${Color.SECONDARY10};
  }

  &.${DropdownType.GENERATOR} {
    background-color: ${Color.SECONDARY80};
  }

  &.${DropdownType.DARK} {
    background: ${Color.SECONDARY10};
    svg {
      fill: ${Color.SECONDARY};
    }
  }

  &.${DropdownType.OUTLINED} {
    border: 1px solid ${Color.PRIMARY10};
  }

  &.error {
    border-color: ${Color.RED_HOT_HOOD};
  }

  + .error {
    color: ${Color.RED_HOT_HOOD};
    font-size: small;
  }

  :hover:not(.disabled):not(.noHoverState),
  &.keepHoverState {
    cursor: pointer;

    &.${DropdownType.TRANSPARENT} {
      border: 1px solid ${Color.PRIMARY10};
    }

    &.${DropdownType.SECONDARY} {
      background: ${Color.PRIMARY05};
    }
    &.${DropdownType.EDITOR} {
      background-color: ${Color.SECONDARY20};
    }

    &.${DropdownType.GENERATOR} {
      background-color: ${Color.SECONDARY};
    }

    &.${DropdownType.DARK} {
      background: ${Color.SECONDARY05};
    }

    &.${DropdownType.OUTLINED} {
      border-color: ${Color.PRIMARY40};
    }
  }
  &.disabled {
    opacity: 0.2;
  }
`;

const SelectedOption = styled.span<
  SelectedOptionProps & { type?: DropdownType; textSize: TextSize }
>`
  color: ${({ color, type }) =>
    type === DropdownType.DARK ? Color.SECONDARY : color};
  font-weight: ${({ fontWeight }) => fontWeight || TextWeight.REGULAR};
  line-height: ${({ textSize }) =>
    LineHeight[textSize] || LineHeight[TextSize.S]};
  font-size: ${({ textSize }) => `${textSize}px` || `${TextSize.S}px`};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.withAvatar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const AvatarWrapper = styled.span`
  margin-right: 16px;
`;

const IconWrapper = styled.span<{ distance?: number }>`
  display: flex;
  ${({ distance }) => distance && `margin-left: ${distance}px;`}
`;

export function Dropdown({
  color = Color.PRIMARY,
  className,
  error,
  items,
  maxVisibleItems,
  type,
  width,
  fontWeight,
  onItemClick,
  listWidth,
  selectedValue,
  selectedState = true,
  footerItems,
  fixedSelectedValue,
  title,
  titleTooltip,
  useAvatar,
  preventSelected,
  disabled,
  autoTopDownDropdownList = false,
  dataTestid,
  noHoverState,
  dropdownPosition,
  iconDistance,
  parentBottomOffset = 0,
  size = DropdownSize.M,
  onDropdownStateChange,
  preventDefaultOnMouseDown = false,
  selectedInputMask,
  hideDropdownOnCollapse = false,
}: DropdownProps) {
  const [dropdownIsVisible, setDropdownIsVisible] = useState<boolean>(false);
  const [dropDownHeight, setDropdownHeight] = useState<number>(0);
  const [selected, setSelected] = useState(selectedValue);
  const [oldSelected, setOldSelected] = useState<
    DropdownListItemValue | null | undefined
  >(null);
  const [dropdownListTop, setDropdownListTop] = useState<number | undefined>(
    undefined
  );
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownListRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      autoTopDownDropdownList &&
      dropdownIsVisible &&
      dropdownListRef?.current &&
      buttonRef?.current
    ) {
      const { top: boundingTop } = buttonRef.current.getBoundingClientRect();

      setDropdownHeight(dropdownListRef.current.offsetHeight);

      if (
        window.innerHeight - boundingTop - buttonRef.current.offsetHeight - 8 <
        dropdownListRef.current.offsetHeight + parentBottomOffset
      ) {
        setDropdownListTop(
          buttonRef.current.offsetTop - dropdownListRef.current.offsetHeight - 8
        );
      } else {
        setDropdownListTop(undefined);
      }
    }
  }, [autoTopDownDropdownList, dropdownIsVisible, parentBottomOffset]);

  useEffect(() => {
    if (hideDropdownOnCollapse) {
      setDropdownIsVisible(false);
    }
  }, [hideDropdownOnCollapse]);

  useEffect(() => {
    setSelected(selectedValue);
    setOldSelected(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    if (onDropdownStateChange) {
      onDropdownStateChange({ hidden: !dropdownIsVisible });
    }
  }, [dropdownIsVisible]);

  const dropdownButtonClassName = `${type} ${error ? 'error' : ''} ${
    className ? className : ''
  } ${disabled ? 'disabled' : ''} ${
    dropdownIsVisible && !noHoverState ? 'keepHoverState' : ''
  } ${noHoverState ? 'noHoverState' : ''}`;

  const onListItemClick = (
    value: DropdownListItemValue,
    fromEnter: boolean
  ) => {
    if (
      (value === selected && !fromEnter) ||
      (value === oldSelected && fromEnter)
    ) {
      return setDropdownIsVisible(false);
    }
    onItemClick(value);
    setDropdownIsVisible(false);
    if (!preventSelected) {
      setSelected(value);
    }
  };

  const hideDropdown = () => {
    if (oldSelected) {
      setSelected(oldSelected);
    }
    setDropdownIsVisible(false);
  };

  // general working solution for dropdowns
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const dropdownListCurrent = dropdownListRef.current;
      const buttonRefCurrent = buttonRef.current;

      if (
        event.target instanceof Node &&
        dropdownListCurrent &&
        !dropdownListCurrent.contains(event.target) &&
        buttonRefCurrent &&
        !buttonRefCurrent.contains(event.target)
      ) {
        return setDropdownIsVisible(!dropdownIsVisible);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownIsVisible]);

  const getSelectedValueDisplay = (value?: string | number) => {
    if (selectedInputMask)
      return selectedInputMask.replace('{{item}}', value?.toString() ?? '');

    return value;
  };

  const dropdown =
    dropdownIsVisible && items.length > 0 ? (
      <DropdownList
        items={items}
        maxVisibleItems={maxVisibleItems}
        size={size}
        onItemClick={(value: any, fromEnter: any) => {
          onListItemClick(value, fromEnter);
        }}
        onItemMouseDown={(_: any, __: any, e: any) => {
          if (preventDefaultOnMouseDown) {
            e?.preventDefault();
          }
        }}
        width={listWidth}
        top={dropdownListTop}
        selectedValue={selected}
        footerItems={footerItems}
        hideDropdown={hideDropdown}
        selectedState={selectedState}
        title={title}
        titleTooltip={titleTooltip}
        ref={dropdownListRef}
        setSelected={setSelected}
        className={`${className}-dropdown-list`}
      />
    ) : null;

  const dropdownSelectedOption = items?.find(({ value }) => value === selected);

  let dropdownElement = (
    <>
      <DropdownButton
        data-testid="dropdown-button"
        width={width}
        className={dropdownButtonClassName}
        onClick={() => !disabled && setDropdownIsVisible(!dropdownIsVisible)}
        ref={buttonRef}
        onMouseDown={(e) => {
          if (preventDefaultOnMouseDown) {
            e.preventDefault();
          }
        }}
      >
        <SelectedOption
          color={fixedSelectedValue ? color : Color.PRIMARY}
          fontWeight={fontWeight}
          className={useAvatar ? 'withAvatar' : ''}
          textSize={TextSize.S}
        >
          <>
            {useAvatar && (
              <AvatarWrapper>
                <BCAvatar
                  size={AvatarSize.S}
                  key="avatar"
                  url={dropdownSelectedOption?.content?.avatar?.url}
                  text={dropdownSelectedOption?.content?.text}
                />
              </AvatarWrapper>
            )}
            <SelectedOption
              color={fixedSelectedValue ? color : Color.PRIMARY}
              type={type}
              fontWeight={fontWeight}
              textSize={TextSize.S}
            >
              {fixedSelectedValue
                ? typeof fixedSelectedValue === 'number' ||
                  typeof fixedSelectedValue === 'string'
                  ? getSelectedValueDisplay(fixedSelectedValue)
                  : fixedSelectedValue
                : getSelectedValueDisplay(
                    dropdownSelectedOption?.content?.text
                  )}
            </SelectedOption>
          </>
        </SelectedOption>
        <IconWrapper distance={iconDistance}>
          <BCIcon icon={IconType.chevronDown} color={color} />
        </IconWrapper>
      </DropdownButton>
      {error && <span className="error">{error}</span>}
      <DropdownWrapper
        position={dropdownPosition}
        ref={dropdownRef}
        hide={autoTopDownDropdownList && !dropDownHeight}
        data-testid={dataTestid}
        width={listWidth}
      >
        {dropdown}
      </DropdownWrapper>
    </>
  );

  if (dropdownPosition) {
    dropdownElement = <DropdownContainer>{dropdownElement}</DropdownContainer>;
  }

  return dropdownElement;
}

function getStyleForPosition(position: DropdownPosition) {
  switch (position) {
    case DropdownPosition.RIGHT:
    default:
      return css`
        left: 0;
      `;
    case DropdownPosition.LEFT:
      return css`
        right: 0;
      `;
    case DropdownPosition.CENTER:
      return css`
        right: auto;
        left: 50%;
        transform: translate(-50%, 0);
      `;
  }
}
