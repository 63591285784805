import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Color from '../constants/Color';

export type ProgressBarProps = {
    width?: string;
    progressPercent: number;
    color?: Color;
};

const ProgressBarContainer = styled.div<{ width: string }>`
    width: ${({ width }) => width};
    height: 18px;
    background-color: ${Color.PRIMARY20};
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 2px;
`;

const ProgressBarComponent = styled.div<{ progressPercent: number; color: string }>`
    min-width: 14px;
    width: ${({ progressPercent }) => progressPercent}%;
    height: 100%;
    background-color: ${({ color }) => color};
    border-radius: 8px;
    transition: width 0.2s ease-in-out;
`;

export function BCProgressBar({ progressPercent, width = '100%', color }: PropsWithChildren<ProgressBarProps>) {
    return (
        <ProgressBarContainer width={width}>
            <ProgressBarComponent color={color || Color.PRIMARY} progressPercent={progressPercent} />
        </ProgressBarContainer>
    );
}
