import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import { ButtonSize, Color, TextSize, TextWeight } from '../constants';

export interface BCDropListOptionsType {
  icon?: string;
  text: string;
  styleClass?: string;
  value: string;
  note?: string;
}

export interface BCDropListProps {
  className?: string;
  selected?: string;
  options: BCDropListOptionsType[];
  onSelectOption: (value?: string) => void;
  noEmpty?: boolean;
  emptyText?: string;
  placeholder?: string;
  buttonSize?: ButtonSize;
  rtl?: boolean;
  icon?: React.ReactNode;
}

const Container = styled.div<{ rtl?: boolean }>`
  display: inline-block;
  position: relative;
  .drop-icon {
    position: absolute;
    top: 6px;
    ${({ rtl }) => (rtl ? 'left: 10px;' : 'right: 10px;')};
  }
  .drop-btn {
    height: 40px;
    color: ${Color.FONT_COLOR};
    background-color: ${Color.CONTROLS_COLOR};
    border: 0.5px solid ${Color.DARK_BLUE};
    cursor: pointer;
    font-size: ${TextSize.S}px;
    font-weight: ${TextWeight.REGULAR};
    line-height: 1;
    padding: 10px 15px;
    position: relative;
    text-transform: unset;
  }
  .drop-btn:hover {
    background: none;
    background-color: ${Color.PRIMARY10};
  }
  .dropbtn-icon {
    margin: -18px 0px 0 0px;
    position: relative;
  }
  .placeholder {
    font-size: ${TextSize.XS};
  }
`;

const DropDownContent = styled.div`
  background-color: #fff;
  border: 0.5px solid ${Color.PRIMARY};
  border-radius: var(--bc-radius);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.05);
  position: absolute;
  width: 100%;
  z-index: 5;
  top: 46px;
  max-height: 300px;
  overflow: auto;
  a {
    color: ${Color.FONT_COLOR};
    font-weight: ${TextWeight.REGULAR};
    padding: 5px 10px;
    display: block;
    cursor: pointer;
  }
  a:first-of-type {
    border-top-right-radius: var(--bc-radius);
    border-top-left-radius: var(--bc-radius);
  }
  a:last-child {
    border-bottom-right-radius: var(--bc-radius);
    border-bottom-left-radius: var(--bc-radius);
  }
  a:hover {
    background-color: ${Color.PRIMARY10};
    color: var(--bc-primary-darker);
  }
`;

const DropButtonNote = styled.span`
  right: 50px;
  position: absolute;
  font-size: 12px;
`;

const DropDownItemNote = styled.span`
  float: right;
  font-size: 12px;
`;

const Icon = styled.i<{ rtl?: boolean }>`
  ${({ rtl }) => (rtl ? 'right: 0px;' : 'left: 0px;')};
  top: 15px;
`;

export const BCDropList = ({
  className = '',
  selected,
  options,
  onSelectOption,
  noEmpty = false,
  placeholder,
  emptyText,
  buttonSize = ButtonSize.M,
  rtl,
  icon,
}: BCDropListProps) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  let selectedText = '';
  let selectedStyle: string | undefined = '';
  let selectedIcon: string | undefined = '';
  let selectedNote: string | undefined = '';
  let selectedObj;
  if (options) {
    selectedObj = options.filter((m) => m.value === selected);
  }
  if (selectedObj && selectedObj.length > 0) {
    selectedText = selectedObj[0].text;
    selectedIcon = selectedObj[0].icon;
    selectedNote = selectedObj[0].note;
    selectedStyle = selectedObj[0].styleClass;
  }

  const showMenuHandler = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (showMenu) return closeMenuHandler();
      setShowMenu(true);
      setTimeout(
        () => document.addEventListener('click', closeMenuHandler),
        500
      );
    },
    [showMenu, setShowMenu]
  );

  const closeMenuHandler = useCallback(() => {
    setShowMenu(false);
    document.removeEventListener('click', closeMenuHandler);
  }, [setShowMenu]);

  return (
    <Container rtl={rtl} className={'dropdown ' + className}>
      <button
        className={
          `drop-btn ${buttonSize} ` +
          (selectedObj && selectedObj.length > 0 ? ' selected ' : '') +
          (selectedStyle ? selectedStyle : '')
        }
        onClick={showMenuHandler}
        type="button"
      >
        {selectedIcon ? (
          <Icon rtl={rtl} className={selectedIcon + ' dropbtn-icon'}></Icon>
        ) : icon && typeof icon === 'string' ? (
          <Icon className={icon}></Icon>
        ) : (
          <Icon className="dropbtn-icon">{icon}</Icon>
        )}

        {selectedText.length > 0 ? (
          <span className="translate">{selectedText}</span>
        ) : (
          <span className="placeholder">{placeholder}</span>
        )}
        <DropButtonNote>{selectedNote}</DropButtonNote>

        <span className="drop-icon">
          {showMenu ? (
            <ChevronUpIcon width={22} height={22} />
          ) : (
            <ChevronDownIcon width={22} height={22} />
          )}
        </span>
      </button>
      {showMenu ? (
        <DropDownContent className="dropdown-content">
          {!noEmpty ? (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowMenu(false);
                onSelectOption(undefined);
              }}
            >
              {emptyText || 'None'}
            </a>
          ) : null}
          {options &&
            options.map((op) => (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setShowMenu(false);
                  onSelectOption(op.value);
                }}
                key={op.value}
                className={op.styleClass}
              >
                {op.icon ? (
                  typeof op.icon === 'string' ? (
                    <Icon className={op.icon}></Icon>
                  ) : (
                    op.icon
                  )
                ) : null}
                {op.text}
                <DropDownItemNote>{op.note}</DropDownItemNote>
              </a>
            ))}
        </DropDownContent>
      ) : null}
    </Container>
  );
};

export default BCDropList;
