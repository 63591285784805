import { ReactNode } from 'react';
import {
  toast,
  ToastContainer as Tostify,
  ToastContainerProps,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from '@emotion/styled';
import { BCButton } from '../button/bcbutton';
import { BCText } from '../text/bctext';
import { BCIcon, IconSize } from '../icon/bcicon';
import {
  Color,
  IconType,
  TextSize,
  TextWeight,
  ButtonSize,
} from '../constants';

export enum ToastSize {
  S = 'small',
  L = 'large',
}

const ToastSizeConfig = {
  [ToastSize.S]: {
    descriptionWidth: '300px',
    marginButton: 'initial',
    contentDirection: 'initial',
    alignItem: 'center',
  },
  [ToastSize.L]: {
    descriptionWidth: '370px',
    marginButton: 'auto',
    contentDirection: 'column',
    alignItem: 'start',
  },
};

type ToastProps = {
  closeToast?: () => void;
  title: ReactNode;
  onFirstButtonClick?: () => void;
  firstButtonText?: string;
  onSecondButtonClick?: () => void;
  secondButtonText?: string;
  onClose?: () => void;
  description?: string;
  autoClose?: number | false;
  size?: ToastSize;
  icon?: IconType;
  titlePaddingRight?: number;
  fullWidth?: boolean;
};

interface StyleProps {
  size: ToastSize;
}

const WrappedToastContainer = ({
  className,
  ...rest
}: ToastContainerProps & { className?: string }) => (
  <div className={className}>
    <Tostify {...rest} hideProgressBar closeOnClick={false} />
  </div>
);

const StyledToastContainer = styled(WrappedToastContainer)`
  .Toastify__toast-container {
    width: auto;
    margin-bottom: 15px;
    margin-right: 15px;
  }
  .toast button[aria-label='close'] {
    display: none;
  }
  .toast {
    border-radius: 8px;
    background-color: ${Color.PRIMARY_DARKER80};
    padding: 0;
    cursor: default;
    min-height: auto;
  }
  .toast-body {
    padding: 10px;
    margin: 0;
    min-height: auto;
  }
`;

export const BCToastContainer = ({
  className,
  ...rest
}: ToastContainerProps & { className?: string }) => {
  return (
    <StyledToastContainer
      className="toast-container"
      toastClassName="toast"
      bodyClassName="toast-body"
      progressClassName="toast-progress"
      {...rest}
    />
  );
};

const TitleWrapper = styled(BCText)<{
  paddingRight?: number;
  fullWidth?: boolean;
}>`
  padding: 0 6px;
  padding-right: ${({ paddingRight }) =>
    paddingRight ? `${paddingRight}px` : '6px'};
  max-width: ${({ fullWidth }) => (fullWidth ? 'unset' : '450px')};
`;

const DescriptionWrapper = styled.div<StyleProps>`
  max-width: ${({ size }) => ToastSizeConfig[size].descriptionWidth};
  padding: 6px 0;
`;

const Container = styled.div<StyleProps>`
  display: flex;
  align-items: center;

  &.title {
    margin-bottom: 3px;
  }

  &.description {
    flex-direction: ${({ size }) => ToastSizeConfig[size].contentDirection};
    align-items: ${({ size }) => ToastSizeConfig[size].alignItem};
  }

  &.buttons {
    margin-left: ${({ size }) => ToastSizeConfig[size].marginButton};
  }
`;

const Separator = styled.div`
  width: 8px;
`;

const MainWrapper = styled.div<{ extraPadding?: string }>`
  padding: ${({ extraPadding }) => (extraPadding ? '8px' : 'initial')};
`;

function ToastInnerContent({
  closeToast,
  title,
  description,
  onFirstButtonClick,
  firstButtonText = '',
  onSecondButtonClick,
  secondButtonText,
  size = ToastSize.S,
  icon = IconType.info,
  titlePaddingRight,
  fullWidth,
}: ToastProps) {
  function handleFirstButton() {
    if (onFirstButtonClick) {
      onFirstButtonClick();
    }
    if (closeToast) {
      closeToast();
    }
  }
  function handleSecondButton() {
    if (onSecondButtonClick) {
      onSecondButtonClick();
    }
    if (closeToast) {
      closeToast();
    }
  }

  const buttons = (firstButtonText || secondButtonText) && (
    <Container size={size} className={'buttons'}>
      {firstButtonText && (
        <BCButton
          onClick={handleFirstButton}
          buttonSize={ButtonSize.S}
          text={firstButtonText}
        />
      )}
      {secondButtonText && <Separator />}
      {secondButtonText && (
        <BCButton
          onClick={handleSecondButton}
          buttonSize={ButtonSize.S}
          text={secondButtonText}
        />
      )}
    </Container>
  );

  return (
    <MainWrapper extraPadding={description}>
      <Container size={size}>
        <Container size={size} className={'title'}>
          <BCIcon icon={icon} color={Color.CONTROLS_COLOR} size={IconSize.L} />
          <TitleWrapper paddingRight={titlePaddingRight} fullWidth={fullWidth}>
            <BCText
              color={Color.CONTROLS_COLOR}
              weight={TextWeight.BOLD}
              size={TextSize.XS}
              ellipsis={size === ToastSize.S}
              maxWidth={size === ToastSize.S ? '450px' : undefined}
            >
              {title}
            </BCText>
          </TitleWrapper>
        </Container>
        {description ? null : buttons}
      </Container>
      {description && (
        <Container size={size} className={'description'}>
          <DescriptionWrapper size={size}>
            <BCText color={Color.CONTROLS_COLOR} size={TextSize.XXS}>
              <span
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </BCText>
          </DescriptionWrapper>
          {buttons}
        </Container>
      )}
    </MainWrapper>
  );
}

export function triggerToast({
  title,
  description,
  firstButtonText = '',
  onFirstButtonClick,
  onSecondButtonClick,
  secondButtonText,
  autoClose,
  onClose,
  size,
  icon,
  titlePaddingRight,
  fullWidth = false,
}: ToastProps) {
  return toast(
    <ToastInnerContent
      title={title}
      description={description}
      firstButtonText={firstButtonText}
      onFirstButtonClick={onFirstButtonClick}
      onSecondButtonClick={onSecondButtonClick}
      secondButtonText={secondButtonText}
      size={size}
      icon={icon}
      titlePaddingRight={titlePaddingRight}
      fullWidth={fullWidth}
    />,
    {
      position: toast.POSITION.BOTTOM_RIGHT,
      onClose,
      autoClose,
    }
  );
}

export function triggerErrorToast({
  title,
  description,
  firstButtonText = '',
  onFirstButtonClick,
  onSecondButtonClick,
  secondButtonText,
  autoClose,
  onClose,
  size,
  icon,
  titlePaddingRight,
  fullWidth = false,
}: ToastProps) {
  return toast(
    <ToastInnerContent
      title={title}
      description={description}
      firstButtonText={firstButtonText}
      onFirstButtonClick={onFirstButtonClick}
      onSecondButtonClick={onSecondButtonClick}
      secondButtonText={secondButtonText}
      size={size}
      icon={IconType.warning}
      titlePaddingRight={titlePaddingRight}
      fullWidth={fullWidth}
    />,
    {
      position: toast.POSITION.BOTTOM_RIGHT,
      onClose,
      autoClose,
    }
  );
}

export function closeAllToast() {
  return toast.dismiss();
}
