import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { withApollo } from 'next-with-apollo';
import fetch from 'isomorphic-unfetch';

// Update the GraphQL endpoint to any instance of GraphQL that you like
const GRAPHQL_URL = 'https://query.bcrumbs.net/graphql';
// const GRAPHQL_URL = 'https://localhost:44322/graphql';

const link = createHttpLink({
  fetch, // Switches between unfetch & node-fetch for client & server.
  uri: GRAPHQL_URL,
});

export const showcaseClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  //  rehydrate the cache using the initial data passed from the server:
  // .restore(initialState || {}),
});

// Export a HOC from next-with-apollo
// Docs: https://www.npmjs.com/package/next-with-apollo
export const withShowcaseClient = withApollo(
  ({ initialState }) =>
    // @ts-expect-error will be fixed later
    showcaseClient
);
