import { ApolloClient, InMemoryCache, from, ServerError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { RestLink } from 'apollo-link-rest';
import { appConfig } from '../config';

const { networkInterface: uri } = appConfig.dconfig;

async function getUserToken() {
  return new Promise((resolve) => {
    if (typeof window !== 'undefined') {
      const storedToken = window.localStorage.getItem('token');
      resolve(storedToken);
    }

    resolve(undefined);
  });
}
async function getUserContext() {
  return new Promise((resolve) => {
    if (typeof window !== 'undefined') {
      const storedContext = window.localStorage.getItem('ContextId');
      resolve(storedContext);
    }

    resolve(undefined);
  });
}

const authLink = setContext(async (operation, { headers }) => {
  const currentUsertoken = await getUserToken();
  const currentContext = await getUserContext();
  if (headers === undefined) {
    headers = {};
  }
  const resultHeaders = currentUsertoken
    ? {
        headers: {
          ...headers,
          Accept: 'application/json',
          Ajax: true,
          Authorization: `Bearer ${currentUsertoken}`,
          ContextId: currentContext ? currentContext : 1132,
        },
      }
    : {
        headers: {
          ...headers,
          Accept: 'application/json',
          Ajax: true,
          ContextId: 1132,
        },
      };
  return resultHeaders;
});

const cache = new InMemoryCache();

const errorLink = onError(({ networkError }) => {
  if (networkError && (networkError as ServerError).statusCode === 401) {
    // redirect to login page
    if (typeof window !== 'undefined') {
      return window.location.replace('/account/login');
    }
  } else if (
    networkError &&
    (networkError as ServerError).statusCode > 500 &&
    (networkError as ServerError).statusCode !== 503
  ) {
    if (typeof window !== 'undefined') {
      return window.location.replace(
        '/account/error?message=' + networkError.message
      );
    }
  } else {
    if (typeof window !== 'undefined') {
      return window.location.replace(
        '/account/error?message=Cannot connect to the server, please make sure you have an active internet connection'
      );
    }
  }
});

/* eslint-disable no-process-env */
const isDevEnv = process.env['NODE_ENV'] !== 'production';
/* eslint-enable no-process-env */

const link_dconfig = new RestLink({
  uri: uri || 'https://api.bcrumbs.net',
  bodySerializers: {
    fileEncode: (data: any, headers: Headers) => {
      const formData = new FormData();
      formData.append('file', data, data.name);
      headers.set('Accept', '*/*');
      return { body: formData, headers };
    },
  },
});

const dconfigClient = new ApolloClient({
  link: from([authLink, errorLink, link_dconfig]),
  cache,
  connectToDevTools: isDevEnv,
  queryDeduplication: true,
});

const formSerializer = (data: any, headers: Headers) => {
  let params = '';
  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key)) {
      params += key + '=' + data[key] + '&';
    }
  }
  headers.set('Content-Type', 'application/x-www-form-urlencoded');
  return { body: params, headers };
};

const link_dconfig_token = new RestLink({
  uri: uri || 'https://api.bcrumbs.net',
  defaultSerializer: formSerializer,
});

const tokenClient = new ApolloClient({
  link: from([link_dconfig_token, errorLink]),
  cache,
  connectToDevTools: isDevEnv,
});

export { tokenClient, dconfigClient };
