import styled from '@emotion/styled';

export interface BCBlockProps {
  className?: string,
  id?: string,
  children?: any,
  height?: number;
};

const StyledBCBlock = styled.div<{ height: number }>`
  display: inline-block;
  margin: 5px;
  width: 400px;
  height: ${({ height }) => height}px;
  padding: 10px 15px 10px;
  position: relative;
`;

export const BCBlock = ({className, id, children, height = 650}: BCBlockProps) => {
  return (
    <StyledBCBlock className={className} key={id} height={height}>
      {children}
    </StyledBCBlock>
  );
}

export default BCBlock;