import { LegacyRef, MouseEvent, ReactElement, useState } from 'react';
import Loader from 'react-loader-spinner';
import styled from '@emotion/styled';
import { ButtonSize, Color } from '../constants';

const StyledButton = styled.button<{ icon?: string | ReactElement }>`
  outline: none;
  cursor: pointer;
  position: relative;
  ${({ icon }) => (icon ? `padding-left: 20px;` : '')};
`;

const StyledIcon = styled.i`
  width: 25px;
  height: 25px;
  margin-bottom: -9px;
  margin-right: 5px;
  position: absolute;
  top: 13px;
  left: 6px;
`;

const Loading = styled.span`
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
  right: 4%;
  top: 13px;
  background-size: contain;
`;

const Container = styled.div<{ icon?: string | ReactElement }>`
  display: inline-block;

  button {
    border-radius: 10px 10px 10px 10px;
    width: 99px;
    border-right: 1px solid #fff;
    margin-right: 35px;
    outline: none;
    position: relative;
    ${({ icon }) => (icon ? `padding-left: 20px;` : '')};
  }
  i {
    cursor: pointer;
    top: 10px;
    right: 0px;
    position: absolute;
  }
  .dropdown-content .dropdown-content2 {
    margin-top: -10px;
    text-align: center;
  }
  .dropdown-content {
    margin-left: 19px;
    a {
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
      background-color: #fff;
      border-bottom: solid 1px #f3f3f3;
    }
    a:hover {
      background-color: rgba(0, 0, 0, 0.1);
      color: #4d4d4d;
    }
  }
`;

const DropdownIcon = styled.i`
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuNCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzNSAzNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzUgMzU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7ZmlsbDojNjYyZDkxO30KCS5zdDF7Y2xpcC1wYXRoOnVybCgjU1ZHSURfMl8pO30KCS5zdDJ7Y2xpcC1wYXRoOnVybCgjU1ZHSURfNF8pO2ZpbGw6I0ZGRkZGRjt9Cjwvc3R5bGU+CjxnPgoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTI1LDM1SDBWMGgyNWM1LjUsMCwxMCw0LjUsMTAsMTB2MTVDMzUsMzAuNSwzMC41LDM1LDI1LDM1eiIvPgoJPGc+CgkJPGRlZnM+CgkJCTxwYXRoIGlkPSJTVkdJRF8xXyIgZD0iTTI0LjUsMTQuNWMwLjItMC4yLDAuMi0wLjYsMC0wLjhjLTAuMi0wLjItMC42LTAuMi0wLjgsMGwtNi4zLDYuM2wtNi4xLTYuM2MtMC4yLTAuMi0wLjYtMC4yLTAuOCwwCgkJCQljLTAuMiwwLjItMC4yLDAuNiwwLDAuOGw2LjksN0wyNC41LDE0LjV6Ii8+CgkJPC9kZWZzPgoJCTxjbGlwUGF0aCBpZD0iU1ZHSURfMl8iPgoJCQk8dXNlIHhsaW5rOmhyZWY9IiNTVkdJRF8xXyIgIHN0eWxlPSJvdmVyZmxvdzp2aXNpYmxlOyIvPgoJCTwvY2xpcFBhdGg+CgkJPGcgY2xhc3M9InN0MSI+CgkJCTxkZWZzPgoJCQkJPHJlY3QgaWQ9IlNWR0lEXzNfIiB4PSItMTE2LjciIHk9Ii0yNS41IiB3aWR0aD0iMTczIiBoZWlnaHQ9IjE4OSIvPgoJCQk8L2RlZnM+CgkJCTxjbGlwUGF0aCBpZD0iU1ZHSURfNF8iPgoJCQkJPHVzZSB4bGluazpocmVmPSIjU1ZHSURfM18iICBzdHlsZT0ib3ZlcmZsb3c6dmlzaWJsZTsiLz4KCQkJPC9jbGlwUGF0aD4KCQkJPHJlY3QgeD0iNS4zIiB5PSI4LjUiIGNsYXNzPSJzdDIiIHdpZHRoPSIyNC4zIiBoZWlnaHQ9IjE4Ii8+CgkJPC9nPgoJPC9nPgo8L2c+Cjwvc3ZnPgo=');
  height: 35px;
  width: 35px;
  display: inline-block;
`;

export interface BCButtonDropListItem {
  key: string;
  value: string;
}

export interface BCButtonProps {
  className?: string; // Should allow classes for different sizes
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  onSubClick?: (key: string) => void;
  text: string | ReactElement;
  text2?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  loading?: boolean;
  icon?: string | ReactElement;
  hasDropList?: boolean;
  dropListItems?: BCButtonDropListItem[];
  refProp?: LegacyRef<HTMLButtonElement>;
  onClick2?: () => void;
  buttonSize?: ButtonSize;
  buttonType?: string;
  loadingIconPath?: string;
}

export interface BCButtonState {
  showDropdown: boolean;
}

export const BCButton = ({
  className,
  onClick,
  text,
  disabled,
  loading,
  icon,
  refProp,
  hasDropList,
  onClick2,
  text2,
  type = 'button',
  buttonSize = ButtonSize.M,
  buttonType,
  loadingIconPath,
}: BCButtonProps) => {
  const [showDropdown, setShowDropdown] = useState(false);

  return !hasDropList ? (
    <StyledButton
      type={type}
      className={`${buttonSize} ${buttonType} ${className}`}
      onClick={onClick}
      disabled={disabled}
      ref={refProp}
      icon={icon}
    >
      {typeof icon === 'string' ? (
        <StyledIcon className={icon} />
      ) : (
        <StyledIcon>{icon}</StyledIcon>
      )}
      <span className="translate">{text}</span>
      {loading &&
        (loadingIconPath ? (
          <Loading style={{ backgroundImage: loadingIconPath }}></Loading>
        ) : (
          <Loading>
            <Loader type="Puff" color={Color.PRIMARY} height={20} width={20} />
          </Loading>
        ))}
    </StyledButton>
  ) : (
    <Container icon={icon}>
      <button
        type={type}
        className={className}
        onClick={onClick}
        disabled={disabled}
        ref={refProp}
      >
        {typeof icon === 'string' ? (
          <StyledIcon className={icon} />
        ) : (
          <StyledIcon>{icon}</StyledIcon>
        )}
        <span className="mb-fontSize-xs translate">{text}</span>
      </button>
      {showDropdown && (
        <div className="dropdown-content radius mb-light-back-color">
          <div className="dropdown-content2 radius mb-light-back-color">
            <a
              href="#"
              onClick={(event) => {
                event.preventDefault();
                onClick2 && onClick2();
              }}
            >
              <span className="translate">{text2}</span>
            </a>
          </div>
        </div>
      )}
      <DropdownIcon onClick={() => setShowDropdown(!showDropdown)} />
    </Container>
  );
};

BCButton.defaultProps = {
  className: 'mb-sm-btn',
  text: 'Submit',
  loading: false,
  disabled: false,
  hasDropList: false,
  dropListItems: undefined,
};

export default BCButton;
