import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { dqueryClient } from '../../../clients/dqueryClient';
import { auth } from '../../../services/auth';

export const showcaseContentsQuery = gql`
  query ($rootId: Int!, $path: String, $deep: Int!) {
    contents(rootId: $rootId, deep: $deep, path: $path) {
      id
      iId
      name
      title
      metaDescription
      data
      priority
      online
      modelId
      children {
        id
        iId
        name
        data
        priority
        online
        modelId
        children {
          id
          iId
          name
          data
          priority
          online
          modelId
          children {
            id
            iId
            name
            data
            priority
            online
            modelId
            children {
              id
              iId
              name
              data
              priority
              online
              modelId
              children {
                id
                iId
                name
                data
                priority
                online
                modelId
                children {
                  id
                  iId
                  name
                  data
                  priority
                  online
                  modelId
                  children {
                    id
                    iId
                    name
                    data
                    priority
                    online
                    modelId
                    children {
                      id
                      iId
                      name
                      data
                      priority
                      online
                      modelId
                      children {
                        id
                        iId
                        name
                        data
                        priority
                        online
                        modelId
                        children {
                          id
                          iId
                          name
                          data
                          priority
                          online
                          modelId
                          children {
                            id
                            iId
                            name
                            data
                            priority
                            online
                            modelId
                            children {
                              id
                              iId
                              name
                              data
                              priority
                              online
                              modelId
                              children {
                                id
                                iId
                                name
                                data
                                priority
                                online
                                modelId
                                children {
                                  id
                                  iId
                                  name
                                  data
                                  priority
                                  online
                                  modelId
                                  children {
                                    id
                                    iId
                                    name
                                    data
                                    priority
                                    online
                                    modelId
                                    children {
                                      id
                                      iId
                                      name
                                      data
                                      priority
                                      online
                                      modelId
                                      children {
                                        id
                                        iId
                                        name
                                        data
                                        priority
                                        online
                                        modelId
                                        children {
                                          id
                                          iId
                                          name
                                          data
                                          priority
                                          online
                                          modelId
                                          children {
                                            id
                                            iId
                                            name
                                            data
                                            priority
                                            online
                                            modelId
                                            children {
                                              id
                                              iId
                                              name
                                              data
                                              priority
                                              online
                                              modelId
                                              children {
                                                id
                                                iId
                                                name
                                                data
                                                priority
                                                online
                                                modelId
                                                children {
                                                  id
                                                  iId
                                                  name
                                                  data
                                                  priority
                                                  online
                                                  modelId
                                                  children {
                                                    id
                                                    iId
                                                    name
                                                    data
                                                    priority
                                                    online
                                                    modelId
                                                    children {
                                                      id
                                                      iId
                                                      name
                                                      data
                                                      priority
                                                      online
                                                      modelId
                                                      children {
                                                        id
                                                        iId
                                                        name
                                                        data
                                                        priority
                                                        online
                                                        modelId
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useShowcaseContentsQuery = (
  rootId: number,
  deep: number,
  path?: string
) =>
  useQuery(showcaseContentsQuery, {
    fetchPolicy: 'cache-first',
    client: dqueryClient,
    variables: { rootId, deep, path },
  });

export const showcaseTemplatesQuery = gql`
  query ($type: String!) {
    showcasetemplates(type: $type) {
      category
      id
      image
      name
      sourceCompanyId
      exampleUrl
    }
  }
`;

export const showcaseTemplatesQueryOptions: any = {
  options: (props: any) => {
    return { client: dqueryClient, variables: { type: props.type } };
  },
  name: 'showcasetemplates',
  props: ({
    showcasetemplates: { loading, showcasetemplates, refetch },
  }: {
    showcasetemplates: {
      loading: boolean;
      showcasetemplates: any;
      refetch: any;
    };
  }) => {
    return {
      templates: showcasetemplates,
      refetchTemplates: refetch,
      loadingTemplates: loading,
    };
  },
};

export const showcasePagesQuery = gql`
  query ($companyId: Int!, $parentId: Int) {
    pages(companyId: $companyId, parentId: $parentId) {
      id
      name
      priority
      parentId
      createDate
      modelId
      online
      path
    }
  }
`;

export const showcasePagesQueryOptions: any = {
  options: (props: any) => {
    return {
      client: dqueryClient,
      variables: { companyId: auth.getContext(), parentId: props.parentId },
      fetchPolicy: 'no-cache',
    };
  },
  name: 'pages',
  props: ({
    pages: { loading, pages, refetch },
  }: {
    pages: { loading: boolean; pages: any; refetch: any };
  }) => {
    return {
      pages: pages,
      refetchPages: refetch,
      loadingPages: loading,
    };
  },
};

export const useShowcasePagesQuery = (parentId?: number) =>
  useQuery(showcasePagesQuery, {
    fetchPolicy: 'cache-first',
    client: dqueryClient,
    variables: { companyId: auth.getContext(), parentId: parentId },
  });

export const showcaseSectionsQuery = gql`
  query ($companyId: Int!, $parentId: Int!) {
    pageResoruces(companyId: $companyId, parentId: $parentId) {
      id
      name
      priority
      parentId
      createDate
      modelId
      online
      path
    }
  }
`;

export const showcaseSectionsQueryOptions: any = {
  options: (props: any) => {
    return {
      client: dqueryClient,
      variables: { companyId: auth.getContext(), parentId: props.parentId },
      fetchPolicy: 'no-cache',
    };
  },
  name: 'sections',
  props: ({
    sections: { loading, pageResoruces, refetch },
  }: {
    sections: { loading: boolean; pageResoruces: any; refetch: any };
  }) => {
    return {
      sections: pageResoruces,
      refetchSections: refetch,
      loadingSections: loading,
    };
  },
};

export const showcaseSectionQuery = gql`
  query ($companyId: Int!, $id: Int!) {
    pageResoruce(companyId: $companyId, id: $id) {
      name
      title
      metaDescription
      data
      priority
      online
      modelId
      children {
        name
        data
        priority
        online
        modelId
        children {
          name
          data
          priority
          online
          modelId
          children {
            name
            data
            priority
            online
            modelId
            children {
              name
              data
              priority
              online
              modelId
            }
          }
        }
      }
    }
  }
`;

export const useShowcaseSectionQuery = (companyId: number, id: number) =>
  useQuery(showcaseSectionQuery, {
    fetchPolicy: 'cache-first',
    client: dqueryClient,
    variables: { companyId, id },
  });

export const showcaseDomainsQuery = gql`
  query ($companyId: Int!) {
    domains(companyId: $companyId) {
      id
      domainAliases
    }
  }
`;

export const showcaseDomainsQueryOptions: any = {
  options: () => {
    return {
      client: dqueryClient,
      variables: { companyId: auth.getContext(), fetchPolicy: 'no-cache' },
    };
  },
  name: 'domains',
  props: ({
    domains: { loading, domains, refetch },
  }: {
    domains: { loading: boolean; domains: any; refetch: any };
  }) => {
    return {
      domains: domains,
      refetchDomains: refetch,
      loadingDomains: loading,
    };
  },
};

export const useDomainsQuery = () =>
  useQuery(showcaseDomainsQuery, {
    fetchPolicy: 'cache-first',
    client: dqueryClient,
    variables: { companyId: auth.getContext() },
  });

export const showcaseConfig = gql`
  query ($domain: String!) {
    configuration(type: "showcase", domain: $domain)
  }
`;

export const useShowcaseConfig = (domain: string) =>
  useQuery(showcaseConfig, {
    fetchPolicy: 'cache-first',
    client: dqueryClient,
    variables: { domain },
  });

export const showcaseConfigById = gql`
  query ($companyContextId: Int!) {
    configuration(type: "showcase", companyContextId: $companyContextId)
  }
`;

export const useShowcaseConfigById = (companyContextId: number) =>
  useQuery(showcaseConfigById, {
    fetchPolicy: 'cache-first',
    client: dqueryClient,
    variables: { companyContextId },
  });

export const showcaseTemplateSectionsThumbsMap = gql`
  query ($companyContextId: Int!) {
    configuration(
      type: "showcaseSectionsThumbMap"
      companyContextId: $companyContextId
    )
  }
`;

export const useTemplateSectionsThumbsMap = (companyContextId: number) =>
  useQuery(showcaseTemplateSectionsThumbsMap, {
    fetchPolicy: 'cache-first',
    client: dqueryClient,
    variables: { companyContextId },
  });

  export const showcaseContext = gql`
  query ($domain: String!) {
    contextId(domain: $domain)
  }
`;

export const useShowcaseContext = (domain: string) =>
  useQuery(showcaseConfig, {
    fetchPolicy: 'cache-first',
    client: dqueryClient,
    variables: { domain },
  });
