import { BCLoading } from '@bcrumbs.net/bc-ui';
import { BLUR_CONTAINER, LOADING_COLOR } from '../../../config/constants';

export const Loading = ({ show = true }: { show?: boolean }) => (
  <BCLoading
    showBox={show}
    color={LOADING_COLOR}
    blurContainer={BLUR_CONTAINER}
  />
);
