import styled from '@emotion/styled';
import { keyframes, css } from '@emotion/react';
import { Component } from 'react';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: none;
  }
`;

const AnimatedDiv = styled.div<{ viewEnter: boolean }>`
  ${({ viewEnter }) =>
    viewEnter &&
    css`
      opacity: 0;
      animation-name: ${fadeIn};
      animation-timing-function: ease-in;
      animation-duration: 0.7s;
      animation-delay: 0s;
      animation-fill-mode: both;
      height:100vh;
    `};
`;

export const withEnterAnimation = () => {
  return (BaseComponent: any): any => {
    class WithEnterAnimationComponent extends Component {
      override render() {
        const { ...passProps } = this.props;

        return (
          <AnimatedDiv viewEnter={true}>
            <BaseComponent {...passProps} />
          </AnimatedDiv>
        );
      }
    }

    return WithEnterAnimationComponent;
  };
}
