import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  Color,
  TextSize,
  TextWeight,
  TextStyle,
  WhiteSpace,
  LineHeight,
  TextAlign,
} from '../constants';

export type BCTextProps = {
  as?: 'span' | 'label';
  htmlFor?: string;
  size?: TextSize;
  weight?: TextWeight;
  style?: TextStyle;
  align?: TextAlign;
  whiteSpace?: WhiteSpace;
  color?: Color;
  uppercase?: boolean;
  underline?: boolean;
  ellipsis?: boolean;
  className?: string;
  title?: string;
  dataQA?: string;
  lineClamp?: number;
  maxWidth?: string;
  strikeThrough?: boolean;
  breakWords?: boolean;
};

type BCStyledTextProps = {
  as?: 'span' | 'label';
  htmlFor?: string;
  textSize?: TextSize;
  weight?: TextWeight;
  textStyle?: TextStyle;
  textColor?: Color;
  whiteSpace?: WhiteSpace;
  uppercase?: boolean;
  underline?: boolean;
  ellipsis?: boolean;
  className?: string;
  title?: string;
  dataQA?: string;
  lineClamp?: number;
  maxWidth?: string;
  strikeThrough?: boolean;
  breakWords?: boolean;
  textAlign?: string;
};

const StyledText = styled.span<BCStyledTextProps>`
  display: inline-block;
  font-size: ${({ textSize }) => `${textSize}px`};
  font-weight: ${({ weight }) => weight};
  color: ${({ textColor }) => textColor};
  cursor: ${({ htmlFor }) => (htmlFor ? 'pointer' : 'inherit')};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  text-decoration-line: ${({ underline }) =>
    underline ? 'underline' : 'none'};
  text-decoration: ${({ strikeThrough }) =>
    strikeThrough ? 'line-through' : 'none'};
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  word-break: ${({ breakWords }) => (breakWords ? 'break-word' : 'normal')};
  transition: color 0.1s ease-in-out;
  line-height: ${({ textSize }) =>
    textSize ? LineHeight[textSize] : LineHeight[TextSize.M]};
  white-space: ${({ whiteSpace }) => whiteSpace};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ textStyle }) => textStyle && `font-style: ${textStyle};`}
    ${({ ellipsis, lineClamp }) =>
    ellipsis
      ? (lineClamp &&
          css`
            display: -webkit-box;
            -webkit-line-clamp: ${lineClamp};
            -webkit-box-orient: vertical;
            overflow: hidden;
          `) ||
        css`
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
        `
      : ''};
`;

/**
 * This component is used to add text in the application
 */
export function BCText({
  as = 'span',
  htmlFor,
  children,
  size = TextSize.M,
  weight = TextWeight.REGULAR,
  color = Color.PRIMARY,
  style,
  align,
  uppercase = false,
  underline = false,
  ellipsis = false,
  lineClamp,
  className,
  title,
  maxWidth = '',
  strikeThrough,
  whiteSpace = WhiteSpace.NORMAL,
  breakWords = false,
}: PropsWithChildren<BCTextProps>) {
  return (
    <StyledText
      as={as}
      htmlFor={htmlFor}
      textSize={size}
      weight={weight}
      textColor={color}
      textStyle={style}
      textAlign={align}
      title={title}
      uppercase={uppercase}
      underline={underline}
      ellipsis={ellipsis}
      className={className}
      lineClamp={lineClamp}
      maxWidth={maxWidth}
      strikeThrough={strikeThrough}
      whiteSpace={whiteSpace}
      breakWords={breakWords}
    >
      {children}
    </StyledText>
  );
}

export function BCError({
  htmlFor,
  children,
  style,
  align,
  uppercase = false,
  underline = false,
  ellipsis = false,
  className,
  title,
  maxWidth = '',
  strikeThrough,
  whiteSpace = WhiteSpace.NORMAL,
  breakWords = false,
}: PropsWithChildren<BCTextProps>) {
  return (
    <BCText
      size={TextSize.XS}
      color={Color.DANGER_COLOR}
      htmlFor={htmlFor}
      children={children}
      style={style}
      align={align}
      uppercase={uppercase}
      underline={underline}
      ellipsis={ellipsis}
      className={className}
      title={title}
      maxWidth={maxWidth}
      strikeThrough={strikeThrough}
      whiteSpace={whiteSpace}
      breakWords={breakWords}
    />
  );
}
