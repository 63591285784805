import { NavItem } from '../types';
import LeftNavButton from './leftNavButton';

export type Props = {
  leftLinks: Array<NavItem>;
  onLeftNavButtonClick: (event: any, action?: string) => void;
  className?: string;
  location: any;
  brand: string;
};

const LeftNav = ({
  leftLinks,
  onLeftNavButtonClick,
  className,
  location,
}: Props) => (
  <ul className={'nav navbar-nav ' + className}>
    {leftLinks.map(
      (
        link,
        index
      ) => {
        return (
          <LeftNavButton
            key={index}
            {...link}
            onClick={onLeftNavButtonClick}
            active={location && location.pathname.indexOf(link.link) === 0}
          />
        );
      }
    )}
  </ul>
);

export default LeftNav;
