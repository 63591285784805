import { useState, useEffect } from 'react';

/**
 * Custom hook to debounce a function.
 *
 * @param callback - The callback function to be called after debouncing.
 * @param delay - The debounce delay in milliseconds.
 * @returns A function to update the debounced value.
 */
function useDebounce<T>(callback: (value: T) => void, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState<T>(undefined as unknown as T);

  useEffect(() => {
    // Set a timeout to call the callback after the delay
    const handler = setTimeout(() => {
      callback(debouncedValue);
    }, delay);

    // Cleanup the timeout if the value changes before the delay is over
    return () => {
      clearTimeout(handler);
    };
  }, [debouncedValue, delay, callback]);

  return setDebouncedValue;
}

export default useDebounce;
