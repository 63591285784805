import { ChangeEvent } from 'react';
import styled from '@emotion/styled';
import Loader from 'react-loader-spinner';
import { Color } from '../constants';

export interface BCAddButtonProps {
  className?: string;
  placeholder?: string;
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  onSubmit: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}

const Container = styled.div`
  display: flex;
  width: 250px;
  border-radius: 10px;
`;

const Input = styled.input`
  margin-top: 0px;
`;

const Icon = styled.i<{ disabled?: boolean }>`
  font-style: normal;
  position: absolute;
  margin-left: 220px;
  margin-top: 6px;
  font-weight: bold;
  font-size: 24px;
  cursor: pointer;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const BCAddButton = ({
  className = '',
  placeholder = '',
  value = '',
  onSubmit,
  onChange,
  isLoading,
}: BCAddButtonProps) => {
  const isInputEmpty = value.trim() === '';
  const isInvalidEmail = !/^\S+@\S+\.\S+$/.test(value);
  const isDisabled = isInputEmpty || isInvalidEmail;

  return (
    <div className={className}>
      <Container>
        <Input
          type="text"
          className="form-control"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
        {isLoading ? (
          <Icon onClick={onSubmit}>
            <Loader type="Puff" color={Color.PRIMARY} height={20} width={20} />
          </Icon>
        ) : (
          <Icon disabled={isDisabled} onClick={onSubmit}>+</Icon>
        )}
      </Container>
    </div>
  );
};
