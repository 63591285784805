import gql from 'graphql-tag';
import { useQuery } from '@apollo/client'; 
import { dconfigClient } from '../../../clients/dconfigClient';
import { auth } from '../../../services/auth';
import { setContext } from '@apollo/client/link/context';

export const userCompaniesQuery = gql`
  query {
    userCompanies
      @rest(
        type: "Company"
        path: "/Membership/getUserCompanies"
        method: "GET"
      ) {
      Id
      Name
      AccountId
      CreateDate
      SubscriptionType
    }
  }
`;
export const userCompaniesQueryOptions: any = {
  options: () => {
    return { variables: {}, client: dconfigClient };
  },
  name: 'companies',
  props: ({
    companies: { loading, userCompanies, refetch },
  }: {
    companies: { loading: boolean; userCompanies: any; refetch: any };
  }) => {
    return {
      companies: userCompanies,
      refetchCompanies: refetch,
      loading: loading,
    };
  },
};
export const useUserCompaniesQuery = () => useQuery(userCompaniesQuery, { client: dconfigClient });


export const companyUsersQuery = gql`
  query ($CompanyId: String!) {
    companyUsers(CompanyId: $CompanyId)
      @rest(
        type: "Company"
        path: "/Membership/getCompanyUsers?{args}"
        method: "GET"
      ) {
      Id
      UserName
      Email
      IsEnabled
    }
  }
`;

export const subscriptionConfigurationQuery = gql`
  query {
    subscriptionConfiguration(Key: "SubscriptionConfig")
      @rest(
        type: "ShowcaseConfig"
        path: "/DConfig/SettingsAPI/getCustomSettings?{args}"
        method: "GET"
      )
  }
`;
export const subscriptionConfigurationQueryOptions: any = {
  options: () => {
    return { client: dconfigClient };
  },
  name: 'subscriptionConfiguration',
  props: ({
    subscriptionConfiguration: { loading, subscriptionConfiguration, refetch },
  }: {
    subscriptionConfiguration: {
      loading: boolean;
      subscriptionConfiguration: any;
      refetch: any;
    };
  }) => {
    return {
      config: subscriptionConfiguration,
      refetchConfig: refetch,
      loadingConfig: loading,
    };
  },
};

export const createCompany = gql`
  mutation ($body: JSON!) {
    createCompany(body: $body)
      @rest(
        type: "CreateCompanyType"
        path: "/Membership/createCompany"
        method: "POST"
        bodyKey: "body"
      ) {
      result
      obj
      message
    }
  }
`;
export const createCompanyOptions: any = {
  options: {
    client: dconfigClient,
  },
  props: ({
    ownProps,
    mutate,
  }: {
    ownProps: unknown;
    mutate: (payload: unknown) => void;
  }) => ({
    async createCompany(Name: string) {
      try {
        const payload = {
          variables: {
            body: {
              Name,
              OwnerId: auth.getUserInfo()['id'],
              SubscriptionType: 'Showcase-free'
            },
          },
        };
        
        const result = await mutate(payload);
        // @ts-expect-error  will be fixed later
        if (result.data.createCompany.result === 'true') setContext(result.data.createCompany.obj.Id);
        // @ts-expect-error  will be fixed later
        return Promise.resolve(result.data.createCompany);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
};

export const updateCompany = gql`
  mutation ($body: JSON!) {
    updateCompany(body: $body)
      @rest(
        type: "UpdateCompanyType"
        path: "/Membership/updateCompany"
        method: "PUT"
        bodyKey: "body"
      ) {
      result
      message
    }
  }
`;
export const updateCompanyOptions: any = {
  options: {
    client: dconfigClient,
  },
  props: ({
    ownProps,
    mutate,
  }: {
    ownProps: unknown;
    mutate: (payload: unknown) => void;
  }) => ({
    async updateCompany(Id: string, Name: string) {
      try {
        const payload = { variables: { body: { Id, Name } } };
        const result = await mutate(payload);
        // @ts-expect-error will be fixed later
        return Promise.resolve(result.data.updateCompany);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }),
};
