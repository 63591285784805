import {
  ApolloClient,
  InMemoryCache,
  from,
  createHttpLink,
  ServerError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { withApollo } from 'next-with-apollo';
import { appConfig } from '../config';
const { networkInterface: uri } = appConfig.dquery;

const cache = new InMemoryCache();

const errorLink = onError(({ networkError }) => {
  if (networkError && (networkError as ServerError).statusCode === 401) {
    // redirect to login page
    if (typeof window !== 'undefined') {
      return window.location.replace('/account/login');
    }
  } else if (
    networkError &&
    (networkError as ServerError).statusCode > 500 &&
    (networkError as ServerError).statusCode !== 503
  ) {
    if (typeof window !== 'undefined') {
      return window.location.replace('/error?message=' + networkError.message);
    }
  }
});

/* eslint-disable no-process-env */
const isDevEnv = process.env['NODE_ENV'] !== 'production';
/* eslint-enable no-process-env */

const link_dquery = createHttpLink({
  uri,
  fetchOptions: {
    mode: 'cors',
  },
});

const dqueryClient = new ApolloClient({
  link: from([errorLink, link_dquery]),
  cache,
  connectToDevTools: isDevEnv,
  queryDeduplication: true,
});

const withDQueryClient = withApollo(
  ({ initialState }) =>
    // @ts-expect-error TypeScript is complaining
    new ApolloClient({
      link: link_dquery,
      cache: new InMemoryCache()
        //  rehydrate the cache using the initial data passed from the server:
        .restore(initialState || {}),
    })
);

export { dqueryClient, withDQueryClient };
