import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { dqueryClient } from '../../../clients/dqueryClient';

export const VALIDATE_COMPANY_NAME = gql`
  query ($name: String!) {
    validateCompanyName(name: $name)
  }
`;

export const useValidateCompanyName = (name: string) =>
  useLazyQuery(VALIDATE_COMPANY_NAME, {
    fetchPolicy: 'cache-first',
    client: dqueryClient,
    variables: { name },
  });
